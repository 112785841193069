import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Grid,
  Button,
  Divider,
  IconButton,
  Avatar,
  Skeleton,
} from "@mui/material";
import { Images } from "../../../assets/images";
import Colors from "../../../assets/styles";
import SliderComponent from "../../../components/Slider/index";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useLocation, useNavigate } from "react-router-dom";
import CategoriesServices from "../../../api/CategoriesServices";
import StoreServices from "../../../api/StoreServices";
import PublicDataHome from "../../../api/PublicDataServices";
import moment from "moment";
import UpperArrowSlider from "../../../components/Slider/UpperArrrowSlider";
import UpperArrowSlider1 from "../../../components/Slider/UpperArrowSlider1";
import ShopServices from "../../../api/ShopServices";
import BannerServices from "../../../api/BannerServices";
import RestaurantCard from "../../../components/RestaurantCard";
import { favoriteApiCall } from "../../../api/favorite";
import { useSelector } from "react-redux";
import BreadCrumbProvider from "../../../hooks/useBreadCrumb";

export default function EditorReview() {
  const [activeFavourite, setActiveFavourite] = useState([]);
  const [shopList, setShopList] = useState([]);
  console.log("🚀 ~ EditorReview ~ shopList:", shopList);
  const [loading, setLoading] = useState(true);
  const [restaurantLoading, setrestaurantLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [publicHomeData, setPublicHomeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [shopCuisineData, setShopCuisineData] = useState(null);
  const [catId, setCatId] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [banner, setBanner] = useState([]);
  const [hasMore, setHasMore] = useState(true); // To check if more data is available
  const [firstLoad, setFirstLoad] = useState(true); // Flag for the first load
  const {
    cartData,
    setCartData,
    badge,
    quantities,
    setQuantities,
    getOrderDetail,
  } = BreadCrumbProvider();
 
  useEffect(() => {
    getOrderDetail();
  }, []);

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const backgroundColors = [Colors.yellow, Colors.red, Colors.green];
  const location = useLocation();
  const userData = useSelector((state) => state.userData.userData);
  // console.log(state)
  // useEffect(() => {
  //   if (page > 1) {
  //     getShopList(page);
  //   }
  // }, [page]);

  useEffect(() => {
    getShopList(page);
  }, [page]);

  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [location]);

  const getHomeData = async () => {
    const day = moment().format("ddd");
    const time = moment().format("HH:mm");

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const data = await PublicDataHome.getPublicDataHome(day, time);
      console.log(data);
      setPublicHomeData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getBannersData = async () => {
    const day = moment().format("ddd");
    const time = moment().format("HH:mm");

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const data = await BannerServices.getBanners();
      console.log(data);
      setBanner(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getCategories = async () => {
    const day = moment().format("ddd");
    const time = moment().format("HH:mm");

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const data = await CategoriesServices.getCategories();
      const filterData = data.filter(
        (category) => category.based_on != "store"
      );
      console.log(filterData);

      setCategoriesData(filterData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getShopList = async (page) => {
    const day = moment().format("ddd");
    const time = moment().format("HH:mm");
    const isPagination = true;
    const limit = 8;

    setrestaurantLoading(true);
    try {
      const data = await ShopServices.getShopList(
        day,
        time,
        isPagination,
        limit,
        page,
        userData?.id ? userData?.id : ""
      );
      setShopList((prevData) => {
        const existingIds = new Set(prevData.map((item) => item.shopid)); // Assuming shopid is the unique identifier
        const newItems = data.shoplist.filter(
          (item) => !existingIds.has(item.shopid)
        );
        return [...prevData, ...newItems];
      });
      setrestaurantLoading(false);
      setDefaultStatus(data.shoplist);
      // setCount(data.TotalCount)
      setHasMore(data.shoplist.length > 0); // Set if more data is available
      setFirstLoad(false); // Set the first load flag to false
      // if (data.TotalCount > limit * page) {
      //   // Update the page state to fetch the next page
      //   setPage(page + 1);
      // }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (loading || !hasMore || firstLoad) return;

    // Calculate if we are near the bottom of the page (100px from bottom)
    // if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
    //   setPage((prevPage) => prevPage + 1);  // Load the next page
    // }

    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 100
    ) {
      setPage((prevPage) => prevPage + 1); // Load the next page
    }
  };

  const getStoreList = async () => {
    try {
      const data = await StoreServices.getStoreTypes();
      setStoreList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getShopCuisine = async (id) => {
    const day = moment().format("ddd");
    const time = moment().format("HH:mm");
    const user_id =  userData?.id
    const cuisine_id = id || location?.id;
    setCatId(id);
    try {
      const data = await ShopServices.getShopByCuisine(
        day,
        time,
        user_id,
        cuisine_id
      );
      setShopCuisineData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const { state } = location;

    if (state && state.id) {
      getShopCuisine(state.id);
    } else {
      navigate("/home");
    }

    // Clear the state after the first render to prevent refresh issues
    if (state) {
      navigate(location.pathname, { state: null, replace: true });
    }
  }, [location.state]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup the event listener on unmount
    };
  }, [restaurantLoading, hasMore]);

  useEffect(() => {
    getShopList(page);
  }, [page]);

  const showMore = () => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      getShopList(newPage);
      return newPage;
    });
  };

  const handleStatusChange = async (id) => {
    console.log(id);
    const index = activeFavourite.indexOf(id);
    const newStatus = [...activeFavourite];
    let checked;
    if (index == -1) {
      newStatus.push(id);
      checked = true;
    } else {
      newStatus.splice(index, 1);
      checked = false;
    }
    setActiveFavourite(newStatus);
    const obj = {
      isActive: checked,
    };
    // try {
    //   const result = await AdminServices.updateCategory(id, obj);
    //   if (result.responseCode == 200) {
    //     console.log(result);
    //     SuccessToaster(result?.message);
    //   };
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   getAllCategories();
    // }
  };

  const setDefaultStatus = (data) => {
    console.log("🚀 ~ setDefaultStatus ~ data:", data);
    // try {
    //   let status = [];
    //   data.forEach(element => {
    //       status.push(element.$id);
    //   });
    //   console.log("status", status)
    //   setActiveFavourite(status);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const addToFavorite = async (shopId, basedOn, status) => {
    try {
      console.warn(userData?.id, shopId, basedOn, status);
      await favoriteApiCall.addToFavorite(
        userData?.id,
        shopId,
        basedOn,
        status
      );
    } catch (error) {
      console.log("Error adding to favorites:", error);
    }
  };

  useEffect(() => {
    getStoreList();
    getCategories();
    getHomeData();
    getShopList(page);
    getBannersData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup the event listener on unmount
    };
  }, [loading, hasMore, firstLoad]);

  useEffect(() => {
    getShopList(page);
  }, [page]);

  useEffect(() => {
    if (firstLoad) {
      getShopList(page); // Load the first page on component mount
    }
  }, [firstLoad]);

  return (
    <>
      <Container sx={{ mt: 1, maxWidth: "1300px !important" }}>
        {/* //categoies */}
        <Box sx={{ outline: "none", border: "none" }}>
          <UpperArrowSlider
            slidesToShow={11}
            className="cuisine"
            slidesToScroll={10}
            slidesToShow1={3}
            slidesToScroll1={3}
            prevArrowClassName="slick-prev custom-prev"
            nextArrowClassName="slick-next custom-next"
          >
            {loading
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} sx={{display:"flex !important",justifyContent:"center !important"}}>
                    <Skeleton
                      sx={{
                        bgcolor: "#c4c4c4",
                        borderRadius: "50%",
                        mt: { md: 5, xs: 8 },
                      }}
                      variant="rectangular"
                      width={60}
                      height="60px"
                    />
                  </Grid>
                ))
              : categoriesData?.map((elem, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      // padding: "8px",
                      display: "flex !important",
                      flexDirection: "column !important",
                      justifyContent: "center",
                      alignItems: "center  ",
                      cursor: "pointer",
                      outline: "outline",
                      border: "none",
                      mt: { md: 5, xs: 8 },
                      ":hover": {
                        ".cat-image": {
                          transform: "scale(1.2)",
                          transition: "all .3s ease-in-out",
                        },
                        ".cat-name": {
                          textDecoration: "underline",
                          transition: "all .3s ease-in-out",
                        },
                      },
                    }}
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <CardMedia
                      onClick={() => getShopCuisine(elem.id)}
                      component="img"
                      sx={{
                        height: "60px",
                        opacity: "0.9",
                        width: "60px",
                        ml: 1,
                        borderRadius: "50%",
                        outline: "none",
                        border: "none",
                      }}
                      image={
                        elem?.based_on == "store"
                          ? baseUrl + "/docs/files/stores/" + elem.pic
                          : baseUrl + "/docs/files/cuisine/" + elem.pic
                      }
                      className={"cat-image"}
                    />

                    <Box
                      sx={{
                        fontFamily: "Noto Sans",
                        textAlign: "center",
                        mt: 1,
                        fontSize: "12px",
                        fontWeight: catId == elem.id ? 600 : 400,
                        ml: 1,
                        outline: "none",
                        border: "none",
                      }}
                      className={"cat-name"}
                    >
                      {elem.name}
                    </Box>
                  </Grid>
                ))}
          </UpperArrowSlider>
        </Box>
        {/* ///categories data */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {shopCuisineData != null && (
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ fontSize: "15px", fontWeight: "500" }}>
                {shopCuisineData?.length + " Results"}
              </Box>
              <Box
                onClick={() => {
                  setShopCuisineData(null);
                }}
                sx={{
                  fontFamily: "Noto Sans",
                  cursor: "pointer",
                  fontSize: "15px",
                  fontWeight: "500",
                  background: "#f3f3f3",
                  padding: "5px 30px",
                  borderRadius: "20px",
                }}
              >
                Reset
              </Box>
            </Grid>
          )}
          {shopCuisineData?.map((elem, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <Card
                sx={{
                  margin: "0 2px",
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  borderRadius: "20px",
                  cursor: "pointer",
                  p: 2,
                  //  height:"250px"
                }}
              >
                <Grid container>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    onClick={() => navigate(`/store/detail/${elem.shopid}`)}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        height: "160px",
                        opacity: "0.9",
                        objectFit: "contain",
                      }}
                      image={baseUrl + "/docs/files/shops/" + elem.avatar}
                    />
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          ml: 2,
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {elem?.name}
                      </Box>
                      {/* <Box
                  sx={{
                    mr: 2,
                    color: "black",
                    fontSize: "10px",
                    background: Colors.grey,
                    padding: "8px 12px",
                    borderRadius: "50%",
                  }}
                >
                  {elem?.rating ? elem.rating : "0"}
                </Box> */}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ ml: 2, fontSize: "14px", fontWeight: "400" }}>
                        {elem?.shopTimings[0]?.start_time +
                          " - " +
                          elem?.shopTimings[0]?.end_time}
                      </Box>
                      <Box sx={{ ml: 2, fontSize: "14px", fontWeight: "100" }}>
                      {`${
                          elem?.estimated_delivery_time == null
                            ? ""
                            : `${elem?.estimated_delivery_time - 5} - ${
                                elem?.estimated_delivery_time
                              } mins`
                        }`}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ mt: 2 }} />

        {/* //sbanners */}
        <Box sx={{ mt: 2 }}>
          {loading ? (
            <Grid item xs={12}>
              <Skeleton
                sx={{ bgcolor: "#c4c4c4", borderRadius: "20px", height: {
                  xs: "250px",
                  sm: "350px",
                  md: "580px",
                }, }}
                variant="rectangular"
                width="100%"
                // height="250px"
              />
            </Grid>
          ) : (
            <SliderComponent
              slidesToShow={1}
              slidesToScroll={1}
              className={"parent-display"}
            >
              {banner?.map((elem, index) => (
                <Grid item key={index} xs={12} sm={12} md={12}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 
                         "auto",
                      //   sm: "350px",
                      //   md: "680px",
                      // },
                 
                      objectFit: "fill",
                      cursor:"pointer"
                    }}
                    onClick={()=>{
                      elem?.basedon == "shop"
                      ? navigate(`/store/detail/${elem?.shop_id}`)
                      : navigate(`/shop/detail/${elem?.shop_id}`)
                    }}
                    image={`${baseUrl}/docs/files/promotion/${elem?.pic_web}`}
                  />
                </Grid>
              ))}
            </SliderComponent>
          )}
        </Box>

        {/* shops near you  */}
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: { md: "110px", sm: "100px", xs: "77px" },
            }}
          >
            <Box
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                fontFamily: "Noto Sans",
              }}
            >
              Shops
            </Box>
            <Box
              sx={{
                fontSize: "17px",
                fontWeight: "bold",
                cursor: "pointer",
                fontFamily: "Noto Sans",
              }}
              onClick={() => navigate("/all-stores")}
            >
              See All
            </Box>
          </Box>
          <Box>
            <UpperArrowSlider
              slidesToShow={6}
              className="custom-arrow"
              slidesToShow1={2}
              slidesToScroll1={2}
              slidesToScroll={7}
              prevArrowClassName="slick-prev custom-prev"
              nextArrowClassName="slick-next custom-next"
            >
              {loading
                ? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      sm={6}
                      md={4}
                      sx={{ justifyContent: "center" }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "#c4c4c4", borderRadius: "50%" }}
                        variant="rectangular"
                        width={140}
                        height="130px"
                      />
                    </Grid>
                  ))
                : publicHomeData?.shoplist?.map((elem, index) => (
                    <Grid
                      item
                      key={index}
                      sx={{
                        padding: "10px",
                        display: "flex !important",
                        flexDirection: "column !important",
                        justifyContent: "center",
                        alignItems: "center  ",
                      }}
                      xs={12}
                      sm={6}
                      md={4}
                      onClick={() => navigate(`/store/detail/${elem.shopid}`)}
                    >
                      <Card
                        sx={{
                          width: "120px",
                          margin: "0 15px",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            height: "120px",
                            width: "120px",
                            opacity: "0.9",

                          }}
                          image={baseUrl + "/docs/files/shops/" + elem.avatar}
                        />
                      </Card>
                      <Box
                        sx={{
                          textAlign: "center",
                          color: "black",
                          mt: 1,
                          fontSize: "15px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          fontFamily: "Noto Sans",
                        }}
                      >
                        {elem.name}
                      </Box>
                      <Box
                        sx={{
                          textAlign: "center",
                          color: "black",
                          mt: 1,
                          fontSize: "15px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          fontFamily: "Noto Sans",
                        }}
                      >
                        {`${
                          elem?.estimated_delivery_time == null
                            ? ""
                            : `${elem?.estimated_delivery_time - 5} - ${
                                elem?.estimated_delivery_time
                              } mins`
                        }`}
                      </Box>
                    </Grid>
                  ))}
            </UpperArrowSlider>
          </Box>
        </Box>
        {/* //new prod  */}
        {/* <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontSize: "25px", fontWeight: "700" }}>New Products</Box>
            <Box
              sx={{ fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}
            >
              See All
            </Box>
          </Box>
          <SliderComponent
            slidesToShow="4"
            slidesToScroll="1"
            className="custom-arrow"
            prevArrowClassName="slick-prev"
            nextArrowClassName="slick-next"
          >
            {loading
              ? [1, 2, 3, 4].map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Skeleton
                      sx={{ bgcolor: "#c4c4c4", borderRadius: "20px" }}
                      variant="rectangular"
                      width={300}
                      height="180px"
                    />
                  </Grid>
                ))
              : publicHomeData?.newprods?.map((elem, index) => (
                  <Grid
                    item
                    key={index}
                    // sx={{ padding: "10px" }}
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Card
                      sx={{
                        margin: "0 10px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                     
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          zIndex: 1,
                          right: 0,
                          color: Colors.black,
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {activeFavourite.includes(elem.$id) ? (
                          <FavoriteIcon
                            onClick={() => handleStatusChange(elem.$id)} 
                            sx={{ fontSize: "20px", fill: Colors.black }} 
                          />
                        ) : (
                          <FavoriteBorderIcon
                            onClick={() => handleStatusChange(elem.$id)} 
                            sx={{ fontSize: "20px", fill: Colors.black }} 
                          />
                        )}
                      </IconButton>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <CardMedia
                            component="img"
                            sx={{ height: "160px", opacity: "0.9" }}
                            image={baseUrl + "/docs/files/products/" + elem.pic}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          ml: 2,
                          color: "black",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        {elem?.name}
                      </Box>
                     
                    </Box>
                
                  </Grid>
                ))}
          </SliderComponent>
        </Box>
        <Divider sx={{ mt: 2 }} /> */}
        {/* <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontSize: "25px", fontWeight: "700" }}>Banners</Box>
            <Box
              sx={{ fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}
            >
              See All
            </Box>
          </Box>
          <SliderComponent
            slidesToShow="4"
            slidesToScroll="2"
            className="custom-arrow"
            prevArrowClassName="slick-prev"
            nextArrowClassName="slick-next"
          >
            {loading
              ? [1, 2, 3, 4].map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Skeleton
                      sx={{ bgcolor: "#c4c4c4", borderRadius: "20px" }}
                      variant="rectangular"
                      width={300}
                      height="180px"
                    />
                  </Grid>
                ))
              : publicHomeData?.banners?.map((elem, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Card
                      sx={{
                        cursor: "pointer",
                        margin: "0 10px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        borderRadius: "20px",
                      }}
                    >
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <CardMedia
                            component="img"
                            sx={{
                              height: "160px",
                              opacity: "0.9",
                              objectFit: "contain",
                            }}
                            image={baseUrl + "/docs/files/shops/" + elem.avatar}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          ml: 2,
                          color: "black",
                          fontSize: "20px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {elem?.name}
                      </Box>
                    
                    </Box>
                    
                  </Grid>
                ))}
          </SliderComponent>
        </Box> */}
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                fontFamily: "Noto Sans",
              }}
            >
              Store
            </Box>
            {/* <Box
              sx={{ fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}
            >
              See All
            </Box> */}
          </Box>
          <UpperArrowSlider1
            slidesToShow="6"
            slidesToScroll="1"
            className="custom-arrow1"
            prevArrowClassName="slick-prev"
            nextArrowClassName="slick-next"
            data={storeList}
          >
            {loading
              ? [1, 2, 3, 4, 5, 6].map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Skeleton
                      sx={{ bgcolor: "#c4c4c4", borderRadius: "50%" }}
                      variant="rectangular"
                      width={140}
                      height="130px"
                    />
                  </Grid>
                ))
              : storeList?.map((elem, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Box sx={{ textAlign: "center" }}>
                      <Card
                        sx={{
                          width: "100px",
                          margin: "0 auto",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                          borderRadius: "50%",
                          cursor: "pointer",
                          boxShadow: "none !important",
                        }}
                        onClick={() => {
                          if (elem?.name === "Grocery") {
                            navigate(`/shop/detail/${elem.storeType_id}`, {
                              state: {
                                shopId: 1,
                                basedOn: "store",
                                typeId: elem?.storeType_id,
                              },
                            });
                          } else {
                            navigate(`/searchStore`, {
                              state: {
                                storeID: 0,
                                storeType: elem?.storeType_id,
                                headerTitle: elem?.name,
                              },
                            });
                          }
                        }}

                        // onClick={() => navigate(`/shop/detail/${elem.storeType_id}`)}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            height: "100px",
                            width: "100px",
                            opacity: "0.9",
                            objectFit:"contain"
                          }}
                          image={
                            baseUrl + "/docs/files/storeType/" + elem.image
                          }
                        />
                      </Card>
                      <Box
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "600",
                          mt: 1,
                          fontFamily: "Noto Sans",
                        }}
                      >
                        {elem.name}
                      </Box>
                    </Box>
                  </Grid>
                ))}
          </UpperArrowSlider1>
        </Box>
        {/* <Divider sx={{ mt: 2 }} />
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Avatar
                sx={{ width: "80px", height: "80px" }}
                src={Images.coop}
              ></Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ fontSize: "25px", fontWeight: "700" }}>
                  Popular beers
                </Box>
                <Box sx={{ fontSize: "15px", fontWeight: "400" }}>
                  From Co-op
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}
            >
              See All
            </Box>
          </Box>
          <SliderComponent
            slidesToShow="5"
            slidesToScroll="2"
            className="custom-arrow"
            prevArrowClassName="slick-prev"
            nextArrowClassName="slick-next"
          >
            {WineCategory.map((elem, index) => (
              <Grid
                item
                key={index}
                sx={{
                  "&:hover": { boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px" },
                  cursor: "pointer",
                }}
                xs={12}
                sm={6}
                md={4}
              >
                <Card
                  sx={{
                    margin: "0 10px",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    background: Colors.grey,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <CardMedia
                        component="img"
                        sx={{ height: "230px", opacity: "0.9" }}
                        image={elem.img}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      ml: 2,
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Budweiser Limited Edition Beer (4ct, 440 ml)
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ ml: 2, fontSize: "14px", fontWeight: "400" }}>
                    $0.29
                  </Box>
                </Box>
              </Grid>
            ))}
          </SliderComponent>
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Avatar
                sx={{ width: "80px", height: "80px" }}
                src={Images.coop}
              ></Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ fontSize: "25px", fontWeight: "700" }}>
                  Wine for any ocassion
                </Box>
                <Box sx={{ fontSize: "15px", fontWeight: "400" }}>
                  From Co-op
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}
            >
              See All
            </Box>
          </Box>
          <SliderComponent
            slidesToShow="5"
            slidesToScroll="2"
            className="custom-arrow"
            prevArrowClassName="slick-prev"
            nextArrowClassName="slick-next"
          >
            {WineCategory.map((elem, index) => (
              <Grid
                item
                key={index}
                sx={{
                  "&:hover": { boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px" },
                  cursor: "pointer",
                }}
                xs={12}
                sm={6}
                md={4}
              >
                <Card
                  sx={{
                    margin: "0 10px",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    background: Colors.grey,
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                >
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <CardMedia
                        component="img"
                        sx={{ height: "230px", opacity: "0.9" }}
                        image={elem.img}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      ml: 2,
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Budweiser Limited Edition Beer (4ct, 440 ml)
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ ml: 2, fontSize: "14px", fontWeight: "400" }}>
                    $0.29
                  </Box>
                </Box>
              </Grid>
            ))}
          </SliderComponent>
        </Box> */}
        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                fontFamily: "Noto Sans",
              }}
            >
              All Restaurants
            </Box>
          </Grid>

          {loading
            ? Array.from({ length: 8 }).map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Skeleton
                    sx={{ bgcolor: "#c4c4c4", borderRadius: "20px" }}
                    variant="rectangular"
                    width={300}
                    height={180}
                  />
                </Grid>
              ))
            : shopList?.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <RestaurantCard
                    data={item}
                    addToFavorite={addToFavorite}
                    storeType={item?.based_on === "store" ? "store" : "shop"}
                    navigate={() => navigate(`/store/detail/${item?.shopid}`)}
                  />
                </Grid>
              ))}
          {restaurantLoading && hasMore && (
            <Grid item xs={12}>
              <Skeleton
                sx={{ bgcolor: "#c4c4c4", borderRadius: "20px" }}
                variant="rectangular"
                width={300}
                height={180}
              />
            </Grid>
          )}
        </Grid>

        {/* <Box
          sx={{
            background: Colors.black,
            color: Colors.white,
            py: 2,
            width: "150px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            borderRadius: "10px",
            margin: "30px auto",
            cursor: "pointer",
          }}
          onClick={showMore}
        >
          Show More
        </Box> */}
      </Container>
      <Divider sx={{ mt: 3, mb: 2 }} />
    </>
  );
}
