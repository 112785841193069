import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Paper,
  TextField,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CssBaseline,
  Divider,
} from "@mui/material";

import AOS from "aos";

import React, { useEffect, useState } from "react";
import { Images } from "../../../assets/images";
import Colors from "../../../assets/styles";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import SliderComponent from "../../../components/Slider/HomePageSlider";
import FooterV2 from "../../../layout/footerV2";
import Header2 from "../../../layout/dashboard/header/header2";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Tonality } from "@mui/icons-material";

export default function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration
    });
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const texts = [
    "Movie marathon?",
    "Late night at office?",
    "Game night?",
    "Hungry?",
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [texts.length]);
  const slidesData = [
    {
      image: Images.latuce,
      title: "Burger Shack",
      description: "Lettuce Wrap Burger",
      price: "$0.00",
    },
    {
      image: Images.bubba,
      title: "Bubba BBQ",
      description: "Double Smoked Chopped Beef Brisket Sandwich",
      price: "$16.00",
    },
    {
      image: Images.waffle,
      title: "Waffle Monkey",
      description: "No Time To Fry",
      price: "$17.00",
    },
    {
      image: Images.latuce,
      title: "Burger Shack",
      description: "Lettuce Wrap Burger",
      price: "$0.00",
    },
    {
      image: Images.bubba,
      title: "Bubba BBQ",
      description: "Double Smoked Chopped Beef Brisket Sandwich",
      price: "$16.00",
    },
    {
      image: Images.waffle,
      title: "Waffle Monkey",
      description: "No Time To Fry",
      price: "$17.00",
    },
  ];
  return (
    <React.Fragment>
      <CssBaseline />
      <Header2 />

      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Section with Background and Clip Path */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            bgcolor: "#000",
            clipPath: {
              md: "polygon(100% 18%, 57% 100%, 100% 100%)",
              xs: "polygon(100% 57%, 19% 100%, 100% 100%)",
            },
            zIndex: 1,
            bottom: { md: "none", xs: 0 },
          }}
        />

        {/* Container with Content */}
        <Box
          sx={{ position: "relative", zIndex: 2, pt: { md: 10, sm: 4, xs: 4 } }}
        >
          <Container sx={{ mt: 1, maxWidth: "1300px !important" }}>
            <Grid container>
              <Grid item md={6}>
                {/* Blowing Up Box */}
                <Box
                  bgcolor="rgb(239 66 35 / 20%)"
                  color="rgb(239, 66, 35)"
                  display="inline-block"
                  px={2}
                  py="7px"
                  mb={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "75%",
                    gap: "5px",
                    borderRadius: "10px",
                    animation: "blowUp 1.5s infinite",
                  }}
                >
                  <img width="30" height="30" src={Images.smile} alt="Smile" />
                  <Box
                    sx={{ fontSize: { md: "1rem", sm: "1rem", xs: "11px" } }}
                  >
                    The easy way to order food online
                  </Box>
                </Box>

                {/* Main Heading */}
                <Typography
                  component="h1"
                  gutterBottom
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "900",
                    fontSize: { md: "3rem", sm: "3rem", xs: "2.5rem" },
                    color: "#000",
                    // WebkitTextStroke: "1px #000",
                    letterSpacing: "0px",
                  }}
                >
                  {texts[currentIndex]}
                </Typography>

                {/* Subtext */}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    width: "100%",

                    fontSize: { md: "17px", sm: "15px", xs: "13px" },
                    color: "#858b90",
                  }}
                  paragraph
                >
                  Whether you want breakfast, lunch, dinner, or just a snack,
                  order conveniently with Let's Eat for pick up or delivery from
                  restaurants across Grand Cayman.
                </Typography>

                {/* Call to Action Button */}
                <Button
                  variant="contained"
                  sx={{
                    padding: "1rem 0",

                    width: { md: "18rem", sm: "16rem", xs: "8rem" },
                    backgroundImage:
                      "linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                    color: "#fff",
                    transition: ".5s",
                    backgroundSize: "200% auto",
                    borderRadius: "10px",
                    textTransform: "uppercase",
                    mt: 2,
                    fontSize: { md: "1rem", sm: "1rem", xs: "11px" },
                  }}
                  onClick={() => navigate("/home")}
                >
                  Ready?.. Go!
                </Button>

                {/* Features Section */}
                <Box mt={4} display="flex" gap="18px" spacing={2}>
                  <Box display="flex" alignItems="center">
                    <img src={Images.shield} />
                    <Typography
                      variant="body1"
                      sx={{
                        marginLeft: "8px",
                        fontWeight: "bold",
                        fontSize: { md: "14px", xs: "12px" },
                      }}
                    >
                      Place your order
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <img src={Images.shield} />

                    <Typography
                      variant="body1"
                      sx={{
                        marginLeft: "8px",
                        fontWeight: "bold",
                        fontSize: { md: "14px", xs: "12px" },
                      }}
                    >
                      Track it in real time
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Image Section */}
              <Grid
                item
                md={6}
                sx={{
                  position: "relative",
                  mt: { md: 0, sm: "100px", xs: "100px" },
                }}
              >
                <CardMedia
                  component={"img"}
                  sx={{
                    width: "100%",
                    transform: "scale(0.8)",
                    ml: { md: "0", sm: 0, xs: "26px " },
                  }}
                  image={Images.foodImage}
                  alt="Delicious Food"
                />

                <Box
                  className="fast-food"
                  sx={{
                    position: "absolute",
                    top: "15%",
                    left: { md: "0", sm: "0", xs: "-10px" },

                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "10px 15px",
                    zIndex: 2,
                  }}
                >
                  <img
                    src={Images.sendIcon}
                    width="60"
                    height="60"
                    alt="send_icon"
                    style={{
                      position: "absolute",
                      top: "-30px",
                      left: "-15px",
                    }}
                  />
                  <Box
                    component={"p"}
                    sx={{
                      marginLeft: "8px",
                      color: "#000",
                      fontSize: { md: "12px", sm: "10px", xs: "8px" },
                      fontWeight: "bold",
                    }}
                  >
                    fast Food Delivery
                  </Box>
                </Box>

                {/* Real Time Tracking */}
                <Box
                  className="pin-icon"
                  sx={{
                    position: "absolute",
                    top: { md: "46%", sm: "46%", xs: "74%" },
                    left: { md: "27%", sm: "27%", xs: "10%" },
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "10px 16px",
                    zIndex: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Images.pinIcon}
                    width="60"
                    height="60"
                    alt="pin_icon"
                    style={{
                      position: "absolute",
                      top: "-30px",
                      left: "-15px",
                    }}
                  />
                  <Box
                    component={"p"}
                    sx={{
                      color: "#000",
                      fontSize: { md: "12px", sm: "10px", xs: "8px" },
                      fontWeight: "bold",
                    }}
                  >
                    Real Time Tracking
                  </Box>
                </Box>

                {/* Order for Pick Up */}
                <Box
                  className="bag-icon"
                  sx={{
                    position: "absolute",
                    bottom: { md: "15%", sm: "15%", xs: "2%" },
                    left: { md: "55%", sm: "55%", xs: "57%" },
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "10px 15px",
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Images.bag}
                    width="60"
                    height="60"
                    alt="bag_icon"
                    style={{
                      position: "absolute",
                      top: "-30px",
                      left: "-15px",
                    }}
                  />
                  <Box
                    component={"p"}
                    sx={{
                      marginLeft: "8px",
                      color: "#000",
                      fontSize: { md: "12px", sm: "10px", xs: "8px" },
                      fontWeight: "bold",
                    }}
                  >
                    Order for Pick Up.
                  </Box>
                </Box>

                {/* Easy to Use */}
                <Box
                  className="setting-icon"
                  sx={{
                    position: "absolute",
                    top: { md: "45%", sm: "45%", xs: "60%" },
                    right: { md: "7%", sm: "7%", xs: "-2%" },
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "10px 15px",
                    zIndex: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Images.settingIcon}
                    width="60"
                    height="60"
                    alt="setting"
                    style={{
                      position: "absolute",
                      top: "-30px",
                      left: "-15px",
                    }}
                  />
                  <Box
                    component={"p"}
                    sx={{
                      marginLeft: "8px",
                      color: "#000",
                      fontSize: { md: "12px", sm: "10px", xs: "8px" },
                      fontWeight: "bold",
                    }}
                  >
                    Easy to use
                  </Box>
                </Box>

                {/* Testimonial */}
                <Box
                  className="alec-icon"
                  sx={{
                    position: "absolute",
                    top: { md: "-6%", sm: "-6%", xs: "-16%" },
                    right: "10%",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "4px 10px",
                    width: "45%",
                    zIndex: 4,
                  }}
                >
                  <CardMedia
                    component="img"
                    image={Images.letseatIcon}
                    alt="letsEat_icon"
                    sx={{
                      position: "absolute",
                      top: "-30px",
                      left: "-15px",
                      background: Colors.primary,
                      borderRadius: "50%",
                      width: { md: "50px", sm: "50px", xs: "40px" },
                      height: { md: "50px", sm: "50px", xs: "40px" },
                    }}
                  />
                  <Box
                    component={"p"}
                    sx={{
                      color: "#858b90",
                      margin: "10px 0",
                      fontSize: { md: "12px", sm: "11px", xs: "8px" },
                      fontWeight: "bold",
                    }}
                  >
                    Let's Eat is always there for me when I need a quick bite!
                  </Box>
                  <Box
                    component={"p"}
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: { md: "11px", sm: "10px", xs: "8px" },
                    }}
                  >
                    Alec H.
                  </Box>
                  <Box>
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        sx={{
                          color: "orange",
                          fontSize: { md: "11px", sm: "10px", xs: "8px" },
                        }}
                      />
                    ))}
                  </Box>
                </Box>
                <Box
                  className="alec-icon"
                  sx={{
                    position: "absolute",
                    top: "37%",
                    left: "47px",
                    zIndex: 4,
                    transform: "rotate(15deg)",
                    width: "80%",
                  }}
                >
                  <CardMedia
                    component={"img"}
                    image={Images.bubbles}
                    width="80%"
                    height="auto"
                    alt="letsEat_icon"
                    sx={{
                      position: "absolute",
                      top: { md: "-95px", sm: "-95px", xs: "-43px" },
                      left: { md: "-35px", sm: "-35px", xs: "-22px" },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      {/* why choose us Section */}
      <Box
        bgcolor="black"
        color="white"
        sx={{ position: "relative", mt: "-1px" }}
        py={10}
      >
        <Box
          sx={{
            position: "absolute",
            top: { md: "-120px", sm: "-120px", xs: "-57px" },
            left: "0",
            zIndex: 2,
            width: " 6%",
            img: {
              height: { md: "202px", sm: "180px", xs: "100px" },
              width: { md: "81px", sm: "70px", xs: "48px" },
            },
          }}
        >
          <img
            src={Images.gemuese}
            onerror="this.onerror=null;this.src='../images/Images/gemuese_2@2x.png'"
            // width="81"
            // height="202"
            loading="lazy"
            alt="gemuese"
            class="img-fluid"
          />
        </Box>
        <Container sx={{ maxWidth: "1300px" }} textAlign="center">
          <Box
            sx={{
              textAlign: "center",
              position: "relative",
              padding: "2rem 0", // Add padding for vertical spacing
              backgroundColor: "#000", // Set background color to black
              color: "#fff", // Set text color to white
            }}
            className="page-heading text-center position-relative"
          >
            {/* Page Heading */}
            <Box
              component="h2"
              sx={{
                // fontSize: { md: "2.5rem", sm: "2rem", xs: "2rem" },
                fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },
                fontWeight: "bold",
                display: "inline-block",
                marginBottom: "1rem",
                WebkitTextStroke: "2px #fff",
                letterSpacing: "3px",
              }}
            >
              Why Choose{" "}
              <span
                style={{ color: "#ef4223", WebkitTextStroke: "1px #ef4223" }}
              >
                Us
              </span>
              ?
            </Box>

            <img
              src={Images.bendedLine}
              alt="Underline"
              style={{
                position: "absolute",
                width: "8%",
                bottom: "40px",
                // left: "70%",
                transform: "translateX(-50%)",
              }}
              loading="lazy"
            />
          </Box>

          <Box sx={{ backgroundColor: "#000", color: "#fff", mb: 8 }}>
            <Grid
              container
              alignItems="center"
              spacing={4}
              justifyContent="center"
            >
              {/* Left Section */}
              <Grid item md={6} sx={{ position: "relative" }}>
                {/* Main Food Image */}
                <img
                  src={Images.bigBurger}
                  alt="Delicious Food"
                  style={{
                    width: "70%",
                    margin: "0 auto",
                    display: "block",
                    position: "relative",
                    zIndex: 1,
                  }}
                />

                {/* Review Card */}
                <Box>
                  <CardMedia
                    data-aos="fade-right"
                    component="img"
                    image={Images.letseatIcon}
                    alt="letsEat_icon"
                    sx={{
                      position: "absolute",
                      top: {
                        md: "-7px",
                        sm: "15px",
                        xs: "15px",
                      },
                      left: {
                        md: "18px",
                        sm: "0px",
                        xs: "14px",
                      },
                      width: { md: "50px", sm: "45px", xs: "32px" },
                      height: { md: "50px", sm: "45px", xs: "32px" },
                      borderRadius: "50%",
                      zIndex: 30,
                      backgroundColor: "#ef4223",
                    }}
                  />
                  <Card
                    data-aos="fade-right"
                    sx={{
                      position: "absolute",
                      top: { md: "-4%", sm: "0%", xs: "0%" },
                      left: { md: "7%", sm: "25px", xs: "25px" },
                      backgroundColor: "#0e0e0e",
                      color: "#fff",
                      // height: {md:"136px",xs:"150px"},
                      borderRadius: "10px",
                      zIndex: 2,
                      maxWidth: { md: "42%", sm: "42%", xs: "45%" },
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <CardContent
                      sx={{
                        pb: "0 !important",
                        padding: {
                          md: "1rem 2rem",
                          sm: "1rem 2rem",
                          xs: "0rem 1rem 0rem 2rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "10px", sm: "8px", xs: "8px" },
                          color: "#858b90",
                          pt: { md: "10px", sm: "10px", xs: "25px" },
                        }}
                      >
                        I use Let's Eat for the convenience and varied options.
                        I would definitely recommend it to a friend.
                      </Typography>
                      <Typography sx={{ fontSize: "10px" }} mt={1}>
                        Beatriz D.
                      </Typography>
                      {/* Star Ratings */}
                      <Box sx={{ fontSize: "10px" }} mt={1}>
                        {[...Array(5)].map((_, index) => (
                          <StarIcon
                            key={index}
                            sx={{
                              color: "orange",
                              fontSize: {
                                md: "1rem",
                                sm: "0.8rem",
                                xs: "0.8rem",
                              },
                            }}
                          />
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                {/* Map Card */}
                <Card
                  data-aos="zoom-in-up"
                  sx={{
                    position: "absolute",
                    top: "20%",
                    left: "60%",
                    backgroundColor: "#0e0e0e",
                    color: "#fff",
                    borderRadius: "10px",
                    width: { md: "28%", sm: "28%", xs: "35%" },
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: "3",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "100%",
                      pb: "0 !important",
                      height: { md: "100px", sm: "100px", xs: "75px" },
                      p: "5px",
                    }}
                    image={Images.mapImages} // Replace with your map image path
                    alt="Map"
                  />
                  <CardContent
                    sx={{ width: "100%", p: { md: 2, sm: 2, xs: "0px 16px" } }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Tonality
                        sx={{ color: "#858b90", width: "20px", height: "20px" }}
                      />
                      <Typography sx={{ fontSize: "10px" }}>
                        Waterfront <br />
                        <Typography
                          component={"span"}
                          sx={{ color: "#858b90", fontSize: "10px" }}
                        >
                          Restaurant
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <AccessTimeIcon
                          sx={{ color: "#fcac1f", fontSize: "13px" }}
                        />
                        <Typography sx={{ fontSize: "10px", color: "#fcac1f" }}>
                          45 mins
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background: Colors.primary,
                          color: Colors.white,
                          padding: "2px",
                          borderRadius: ".4rem",
                          fontSize: "12px",
                        }}
                      >
                        2 Km
                      </Box>
                    </Box>
                  </CardContent>
                </Card>

                {/* Burger Info */}

                <Card
                  data-aos="fade-right"
                  className="burgerCard"
                  sx={{
                    position: "absolute",
                    bottom: { md: "10%", sm: "8%", xs: "5%" },
                    left: { md: "10%", sm: "8%", xs: "5%" },
                    backgroundColor: "#0e0e0e",
                    color: Colors.white,
                    borderRadius: "10px",
                    width: { md: "36%", sm: "32%" },
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "3",
                  }}
                >
                  <CardContent sx={{ pb: "0 !important" }}>
                    <Typography
                      sx={{ fontSize: { md: "143x", sm: "11px", xs: "10px" } }}
                    >
                      Mac Attack
                    </Typography>
                    <Typography
                      sx={{ fontSize: { md: "13px", sm: "11px", xs: "10px" } }}
                    >
                      Waterfront
                    </Typography>
                    <Box>
                      {[...Array(5)].map((_, index) => (
                        <StarIcon
                          key={index}
                          sx={{
                            color: "orange",
                            fontSize: { md: "12px", sm: "11px", xs: "11px" },
                          }}
                        />
                      ))}
                    </Box>
                  </CardContent>
                  <CardMedia
                    component="img"
                    image={Images.smallBurger}
                    alt="Burger"
                    sx={{
                      width: { md: "75px", sm: "65px", xs: "55px" },
                      height: { md: "80px", sm: "70px", xs: "60px" },
                    }}
                  />
                </Card>
              </Grid>

              {/* Right Section */}
              <Grid item md={6}>
                {/* Header */}
                <Typography
                  sx={{
                    fontWeight: "bold",
                    lineHeight: 1.2,
                    marginBottom: "1rem",
                    color: Colors.primary,
                    fontSize: "20px",
                    fontFamily: "Poppins",
                  }}
                >
                  Restaurants
                </Typography>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontWeight: "bold",
                    // fontSize: { md: "2.5rem", sm: "2.2rem", xs: "28px" },
                    fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

                    width: "80%",
                    WebkitTextStroke: "0.5px #fff",
                    lineHeight: { md: "47px", sm: "47px", xs: "32px" },
                    letterSpacing: "2px",
                  }}
                >
                  {" "}
                  Cayman’s Top Restaurants in your pocket!
                </Typography>
                <img
                  src={Images.straightLine}
                  alt="Underline"
                  style={{
                    width: "200px",
                    marginLeft: "72px",
                    marginTop: "20px",
                    transform: "translateX(-40%)",
                  }}
                  loading="lazy"
                />

                {/* Subtext */}
                <Typography
                  variant="body1"
                  sx={{
                    color: Colors.white,
                    fontSize: { md: "1rem", sm: "1rem", xs: "15px" },
                    my: "2rem",
                    maxWidth: "500px",
                  }}
                >
                  Choose from a huge selection of Cayman’s most popular
                  restaurants and track on the go with the Let’s Eat App.
                </Typography>

                <Box
                  sx={{ display: "flex", gap: "1rem", marginBottom: "2rem" }}
                >
                  <img
                    src={Images.apple}
                    alt="App Store"
                    width="150px"
                    height="48px"
                  />
                  <img
                    src={Images.android}
                    alt="Google Play"
                    width="150px"
                    height="48px"
                  />
                </Box>

                {/* Start Your Experience Button */}
                <Button
                onClick={()=>navigate("/home")}
                  variant="contained"
                  sx={{
                    padding: "15px 30px",
                    color: "#fff",
                    textTransform: "none",
                    borderRadius: "8px",
                    backgroundImage:
                      " linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                    fontSize: { md: "1.2rem", sm: "1.2rem", xs: "11px" },
                    margin: { md: "0", sm: "0", xs: "0 auto" },
                    display: { md: "block", sm: "block", xs: "flex" },

                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                      color: "#fff",
                    },
                  }}
                >
                  Start Your Experience
                </Button>
              </Grid>

              <Box sx={{ position: "absolute", right: "0", zIndex: "3" }}>
                <img style={{ height: "200px" }} src={Images.tomato} />
              </Box>
            </Grid>
          </Box>

          <SliderComponent
            slidesToShow={4}
            slidesToScroll={4}
            className="parent-display"
          >
            {slidesData.map((slide, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: 16,
                    backgroundColor: Colors.white,
                    maxWidth: 400,
                    height: 330,
                    margin: "0 16px",
                  }}
                >
                  <img
                    src={slide.image}
                    alt={slide.title}
                    style={{
                      margin: "0 auto 16px",
                      display: "block",
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ color: "#858b90" }}>
                      {slide.title}
                    </Typography>
                    <Box
                      sx={{
                        backgroundImage:
                          "linear-gradient(450deg,#f5593d 0%,#ef4223 50%,#ef4223 100%)",
                        borderRadius: ".4rem",
                        color: Colors.white,
                        p: "4px 10px",
                        fontSize: "14px",
                      }}
                    >
                      {slide.price}
                    </Box>
                  </Box>
                  <Typography
                    sx={{ fontWeight: "bold", color: Colors.black, mt: 0.7 }}
                  >
                    {slide.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </SliderComponent>
        </Container>
      </Box>
      {/* live order tracking section */}
      <Box bgcolor={Colors.white} py={10} sx={{ position: "relative" }}>
        <Container sx={{ maxWidth: "1300px" }}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center "
            sx={{
              paddingLeft: { md: "10rem", sm: "5rem", xs: "0" },
              paddingRight: { md: "10rem", sm: "9rem", xs: "0" },
              // position:"relative"
            }}
          >
            {/* Left Section: Text and Button */}
            <Box
              sx={{
                position: "absolute",
                top: { md: "", sm: "88px", xs: "88px" },
                left: 0,
                zIndex: "3",
              }}
            >
              <CardMedia
                component={"img"}
                sx={{ height: { md: "450px", sm: "110px", xs: "120px" } }}
                image={Images.halfPizza}
              />
            </Box>
            <Grid
              item
              xs={12}
              md={8}
              sm={12}
              sx={{
                paddingRight: { md: "10rem", sm: "0px" },
                paddingRight: {
                  md: "32px !important",
                  sm: "52px !important",
                  xs: "52px !important",
                },
                paddingLeft: {
                  md: "32px !important",
                  sm: "70px !important",
                  xs: "70px !important",
                },
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ color: Colors.primary, fontWeight: "bold" }}
                gutterBottom
              >
                Tracking
              </Typography>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                fontWeight="bold"
                sx={{
                  fontFamily: "Poppins",
                  WebkitTextStroke: "1px #000",
                  fontSize: { md: "2.6rem", sm: "2.4rem", xs: "24px" },
                  letterSpacing: "2px",
                }}
              >
                Live Order <br /> Tracking
              </Typography>
              <Box
                component="span"
                sx={{
                  display: "block",
                  height: "4px",
                  width: "80px",

                  margin: "16px 0 32px",
                }}
              >
                <img src={Images.straightLine} />
              </Box>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph
                sx={{
                  fontWeight: "600",
                  fontSize: { md: "15px", sm: "15px", xs: "13px" },
                  width: { md: "67%", sm: "100%", xs: "100%" },
                }}
              >
                Track your order live and know where it is at all times, from
                the kitchen to your doorstep. Track live on the Let's Eat App.
              </Typography>
              <Button
                variant="contained"
                size="large"
                sx={{
                  textTransform: "inherit",
                  backgroundImage:
                    "linear-gradient(450deg, #485461 0%, #000 50%, #000 100%)",
                }}
                onClick={() => navigate("/login")}
              >
                Sign up
              </Button>
            </Grid>

            {/* Right Section: Phone Image and Overlapping Cards */}
            <Grid
              item
              xs={12}
              md={4}
              sm={12}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CardMedia
                component="img"
                image={Images.mobile}
                alt="Phone Placeholder"
                sx={{
                  width: "227px",
                  height: "auto",
                  transform: { md: "none", sm: "none", xs: "scale(0.7)" },
                }}
              />
              <CardMedia
                component="img"
                image={Images.bubbles2}
                alt="Phone Placeholder"
                sx={{
                  position: "absolute",
                  width: { md: "480px", sm: "100%", xs: "100%" },
                  left: { md: "-113px", sm: "36px", xs: "1%" },
                  top: { md: "90px", sm: "38px", xs: "40px" },
                }}
                // style={{ width: "227px", height: "auto" }}
              />

              {/* <Paper
                sx={{
                  position: "absolute",
                  top: "20%",
                  left: { md: "-30%", sm: "20%" },
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "10px",
                  width: "150px",
                  padding: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: "135px", height: "100px" }}
                  image={Images.mapImages} 
                  alt="Map"
                />
                <Typography variant="subtitle2">Bluestone Lane</Typography>
                <Typography variant="caption" color="textSecondary">
                  Cafe
                </Typography>
                <Typography variant="caption" color="inherit" mt={1}>
                  45 mins | 2 Km
                </Typography>
              </Paper> */}

              <Box
                sx={{
                  position: "absolute",
                  top: { md: "20%", sm: "20%", xs: "30%" },
                  left: { md: "-25%", sm: "15%", xs: "10px" },
                }}
                data-aos="zoom-in-up"
              >
                <Card
                  sx={{
                    backgroundColor: "#0e0e0e",
                    color: "#fff",
                    borderRadius: "10px",
                    // width: { md: "28%", sm: "28%", xs: "35%" },
                    width: { md: "150px", sm: "150px", xs: "140px" },
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: "3",
                    transform: {
                      md: "none !important",
                      sm: "none !important",
                      xs: "scale(0.7) translateZ(0) !important",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "100%",
                      pb: "0 !important",
                      height: { md: "100px", sm: "100px", xs: "75px" },
                      p: "5px",
                    }}
                    image={Images.mapImages} // Replace with your map image path
                    alt="Map"
                  />
                  <CardContent
                    sx={{ width: "100%", p: { md: 2, sm: 2, xs: "0px 16px" } }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        image={Images.mapInnerImage}
                        sx={{ color: "#fcac1f", width: "20px" }}
                      />
                      <Typography sx={{ fontSize: "10px" }}>
                        Bluestone Lane <br />
                        <Typography
                          component={"span"}
                          sx={{ color: "#858b90", fontSize: "10px" }}
                        >
                          Cafe
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <AccessTimeIcon
                          sx={{ color: "#fcac1f", fontSize: "13px" }}
                        />
                        <Typography sx={{ fontSize: "10px", color: "#fcac1f" }}>
                          45 mins
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background: Colors.primary,
                          color: Colors.white,
                          padding: "2px",
                          borderRadius: ".4rem",
                          fontSize: "12px",
                        }}
                      >
                        2 Km
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Paper
                sx={{
                  position: "absolute",
                  top: "30%",
                  right: { md: "-35%", sm: "17%", xs: "0%" },
                  backgroundColor: Colors.primary,
                  color: "#fff",

                  padding: "4px 12px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                45 min
              </Paper>
              <Box
                data-aos="fade-left"
                sx={{
                  position: "absolute",
                  width:{md:"auto",sm:'150px',xs:"150px"},
                  bottom: { md: "10%", sm: "8%", xs: "15%" },
                  right: { md: "-80px", sm: "8%", xs: "26%" },
                }}
              >
                <Card
                  className="burgerCard"
                  sx={{
                    backgroundColor: "#0e0e0e",
                    color: Colors.white,
                    borderRadius: "10px",
                    // width: { md: "36%", sm: "32%" },
                    width: { md: "220px", sm: "220px", xs: "186px" },
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "3",
                    transform: {
                      md: "none !important",
                      sm: "none !important",
                      xs: "scale(0.6) translateZ(0) !important",
                    },
                  }}
                >
                  <CardContent sx={{ pb: "0 !important" }}>
                    <Typography sx={{ fontSize: "10px" }}>
                      Chicken Sandwich
                    </Typography>
                    <Typography sx={{ fontSize: "10px" }}>
                      Bluestone Lane
                    </Typography>
                    <Box>
                      {[...Array(5)].map((_, index) => (
                        <StarIcon
                          key={index}
                          sx={{
                            color: "orange",
                            fontSize: { md: "12px", sm: "11px", xs: "11px" },
                          }}
                        />
                      ))}
                    </Box>
                  </CardContent>
                  <CardMedia
                    component="img"
                    image={Images.sandWich}
                    alt="Burger"
                    sx={{
                      width: { md: "75px", sm: "65px", xs: "55px" },
                      height: { md: "80px", sm: "70px", xs: "60px" },
                    }}
                  />
                </Card>
              </Box>
              {/* <Paper
                sx={{
                  position: "absolute",
                  right: { md: "-35%", sm: "-22%", xs: "-44%" },
                  bottom: "10%",
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "10px",
                  width: "250px",
                  padding: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="subtitle2" sx={{ fontSize: "10px" }}>Chicken Sandwich</Typography>
                  <Typography variant="caption" sx={{color:"#768b90",fontSize:"10px"}}>
                    Bluestone Lane
                  </Typography>
                  <Box>
                    {[...Array(5)].map((_, index) => (
                      <span
                        key={index}
                        style={{ color: "orange", fontSize: "16px" }}
                      >
                        ★
                      </span>
                    ))}
                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  image={Images.sandWich}
                  alt="Sandwich Placeholder"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "10%",
                    marginRight: "12px",
                  }}
                />
              </Paper> */}
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={4}
              sm={12}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                img: {
                  width: { md: "500px", sm: "400px", xs: "280px" },
                },
              }}
            >
              <img src={Images.screenShot} />
            </Grid> */}
          </Grid>
        </Container>
      </Box>

      {/* Lightning-Fast Delivery section*/}
      <Box bgcolor="black" color="white" py={10}>
        <Container sx={{ maxWidth: "1300px" }} textAlign="center">
          <Box
            sx={{
              textAlign: "center",
              position: "relative",
              padding: "10px 0", // Add padding for vertical spacing
              backgroundColor: "#000", // Set background color to black
              color: "#fff", // Set text color to white
            }}
            className="page-heading text-center position-relative"
          >
            {/* Page Heading */}
            <Box
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",

                color: Colors.primary,
              }}
            >
              Delivery
            </Box>
            <Box
              component="h2"
              sx={{
                // fontSize: { md: "2.5rem", sm: "2.5rem", xs: "1.3rem" },
                fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

                fontWeight: "900",
                textAlign: "center",
                marginBottom: "1rem",
                WebkitTextStroke: {
                  md: "0.5px #fff",
                  sm: "0.5px #fff",
                  xs: "0.5px #fff",
                },
                letterSpacing: "2px",
              }}
            >
              Lightning-Fast Delivery
            </Box>

            <img
              src={Images.straightLine}
              alt="Underline"
              style={{
                position: "absolute",
                width: "15%",
                bottom: "3px",
                // left: "70%",
                transform: "translateX(-40%)",
              }}
              loading="lazy"
            />
          </Box>
          <Box
            component="h2"
            sx={{
              fontSize: { md: "1rem", sm: "1rem", xs: "13px" },
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
              color: "#858b90",
              margin: "16px auto",
              width: { md: "58%", sm: "60%", xs: "100%" },
            }}
          >
            Let's Eat provides super fast delivery so you can enjoy food that is
            fresh and on-time. Your favorite restaurants, at your convenience.
          </Box>

          <Box>
            <CardMedia component="img" image={Images.banenr} />
          </Box>
        </Container>
      </Box>
      {/* Become aLet's Eat Restaurant section */}
      <Box bgcolor={Colors.white} py={10}>
        <Container sx={{ maxWidth: "1300px", width: "95%" }}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            sx={{
              background: "#f1f1f1",
              position: "relative",
              padding: { md: "0", sm: "2rem", xs: "2rem" },
              marginLeft: "-17px !important",
            }}
          >
            {/* Left Section: Text and Button */}
            <Grid item xs={12} md={4}>
              <Typography
                variant="h6"
                sx={{ color: Colors.primary, fontWeight: "bold" }}
                component="div"
                color="secondary"
                gutterBottom
              >
                Join Us
              </Typography>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                fontWeight="900"
                sx={{
                  fontFamily: "Poppins",
                  WebkitTextStroke: {
                    md: "0.5px #000",
                    sm: "0.5px #000",
                    xs: "0.5px #000",
                  },
                  // fontSize: { md: "2.6rem", sm: "2.6rem", xs: "26px" },
                  fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

                  lineHeight: { md: "45px", sm: "45px", xs: "30px" },
                  letterSpacing: "1.2px",
                }}
              >
                Become a <br />
                Let's Eat <br />
                Restaurant
              </Typography>
              <CardMedia
                component={"img"}
                image={Images.straightLine}
                alt="Underline"
                sx={{
                  position: "absolute",
                  width: "150px",
                  // bottom: "3px",
                  left: { md: "107px", sm: "137px", xs: "137px" },
                  transform: "translateX(-50%)",
                }}
                loading="lazy"
              />
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph
                sx={{
                  mt: 6,
                  fontWeight: "600",
                  fontSize: { md: "1rem", sm: "1rem", xs: "12px" },
                }}
              >
                Join us and become a Let's Eat restaurant partner. Sign up and
                expand your customer reach by having your food delivered
                island-wide.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  backgroundImage:
                    " linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                  fontSize: { md: "1rem", sm: "1rem", xs: "11px" },
                  margin: { md: "0", sm: "0", xs: "0 auto" },
                  display: { md: "block", sm: "block", xs: "flex" },
                  textTransform: "inherit",
                }}
                size="large"
                onClick={() => navigate("/enquirydelivery")}
              >
                Partner with Let's Eat
              </Button>
            </Grid>

            {/* Right Section: Images */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {/* Main Restaurant Image */}
                <Grid item xs={12}>
                  <CardMedia
                    component="img"
                    // height="200"
                    image={Images.restranuntImage}
                    alt="Restaurant Placeholder"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* Sign in and Order now section  */}
      {/* <Box
        py={10}
        sx={{
          backgroundImage: `url(${Images.signUp})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          borderRadius: "20px",
          width: "96%",
          margin: "0 auto",
        }}
      >
        <Container sx={{ maxWidth: "1300px" }}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            fontWeight="bold"
            sx={{
              letterSpacing: "2px",
              textAlign: "center",
              WebkitTextStroke: "1.3px #fff",
              mb: 4,
              fontSize: "1.6rem",
            }}
          >
            Sign in and Order now
          </Typography>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "white",
              borderRadius: "22px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              overflow: "hidden",
              p: "4px",
              width: "100%",
              margin: "0 auto",
              maxWidth: "800px", 
            }}
          >
            
            <Grid item md={3.5} xs={3.5}>
              <TextField
                variant="outlined"
                placeholder="Cayman Islands (+345)"
                InputProps={{
                  style: {
                    borderTopLeftRadius: "30px",
                    borderBottomLeftRadius: "30px",
                    border: "none",
                    paddingLeft: "20px",
                    fontSize: "14px",
                  },
                }}
                sx={{
                  width: "200px",
                  bgcolor: "white",
                  "& fieldset": { border: "none" },
                }}
              />
            </Grid>

         
            <Grid
              item
              md={6.5}
              xs={6.5}
              sx={{
                borderLeft:  "4px solid #cccccc",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter your number"
                InputProps={{
                  style: {
                    paddingLeft: "20px",
                    fontSize: "14px",
                  },
                }}
                sx={{
                  width: "100%",
                  bgcolor: "white",
                  "& fieldset": { border: "none" }, 
                }}
              />
            </Grid>

            <Grid
              item
              md={2}
              xs={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{
                  height: "56px",
                  borderRadius: "20px",
                  fontSize:{md:"15px",sm:"14px",xs:"12px"},
                  backgroundColor: Colors.primary,
                  ":hover": {
                    backgroundColor: Colors.primary,
                  },
                  padding: "0 24px",
                }}
                onClick={() => navigate("/login")}
              >
                Sign in
              </Button>
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            sx={{ color: "white", fontSize: "19px" }}
            color="textSecondary"
            mt={2}
            textAlign="center"
          >
            Not a Member?{" "}
            <a
              href="/login"
              style={{ color: "white", textDecoration: "underline" }}
            >
              Sign up
            </a>
          </Typography>
        </Container>
      </Box> */}
      <Container sx={{ maxWidth: "1300px" }}>
        <section className="subscribe">
          <div className="content">
            <h3 style={{ letterSpacing: "2px", fontWeight: "400" }}>
              Sign in and Order now
            </h3>
            <div className="subscribe-input d-flex">
              <select
                name="area_code"
                id="area_code"
                className="form-control"
                style={{ borderRadius: "4px 0px 0px 4px" }}
              >
                <option data-countrycode="KY" value="+1345">
                  Cayman Islands (+1345)
                </option>
                <optgroup label="Other countries">
                  <option data-countrycode="DZ" value="+213">
                    Algeria (+213)
                  </option>
                  <option data-countrycode="AD" value="+376">
                    Andorra (+376)
                  </option>
                  <option data-countrycode="AO" value="+244">
                    Angola (+244)
                  </option>
                  <option data-countrycode="AI" value="+1264">
                    Anguilla (+1264)
                  </option>
                  <option data-countrycode="AG" value="+1268">
                    Antigua &amp; Barbuda (+1268)
                  </option>
                  <option data-countrycode="AR" value="+54">
                    Argentina (+54)
                  </option>
                  <option data-countrycode="AM" value="+374">
                    Armenia (+374)
                  </option>
                  <option data-countrycode="AW" value="+297">
                    Aruba (+297)
                  </option>
                  <option data-countrycode="AU" value="+61">
                    Australia (+61)
                  </option>
                  <option data-countrycode="AT" value="+43">
                    Austria (+43)
                  </option>
                  <option data-countrycode="AZ" value="+994">
                    Azerbaijan (+994)
                  </option>
                  <option data-countrycode="BS" value="+1242">
                    Bahamas (+1242)
                  </option>
                  <option data-countrycode="BH" value="+973">
                    Bahrain (+973)
                  </option>
                  <option data-countrycode="BD" value="+880">
                    Bangladesh (+880)
                  </option>
                  <option data-countrycode="BB" value="+1246">
                    Barbados (+1246)
                  </option>
                  <option data-countrycode="BY" value="+375">
                    Belarus (+375)
                  </option>
                  <option data-countrycode="BE" value="+32">
                    Belgium (+32)
                  </option>
                  <option data-countrycode="BZ" value="+501">
                    Belize (+501)
                  </option>
                  <option data-countrycode="BJ" value="+229">
                    Benin (+229)
                  </option>
                  <option data-countrycode="BM" value="+1441">
                    Bermuda (+1441)
                  </option>
                  <option data-countrycode="BT" value="+975">
                    Bhutan (+975)
                  </option>
                  <option data-countrycode="BO" value="+591">
                    Bolivia (+591)
                  </option>
                  <option data-countrycode="BA" value="+387">
                    Bosnia Herzegovina (+387)
                  </option>
                  <option data-countrycode="BW" value="+267">
                    Botswana (+267)
                  </option>
                  <option data-countrycode="BR" value="+55">
                    Brazil (+55)
                  </option>
                  <option data-countrycode="BN" value="+673">
                    Brunei (+673)
                  </option>
                  <option data-countrycode="BG" value="+359">
                    Bulgaria (+359)
                  </option>
                  <option data-countrycode="BF" value="+226">
                    Burkina Faso (+226)
                  </option>
                  <option data-countrycode="BI" value="+257">
                    Burundi (+257)
                  </option>
                  <option data-countrycode="KH" value="+855">
                    Cambodia (+855)
                  </option>
                  <option data-countrycode="CM" value="+237">
                    Cameroon (+237)
                  </option>
                  <option data-countrycode="CA" value="+1">
                    Canada (+1)
                  </option>
                  <option data-countrycode="CV" value="+238">
                    Cape Verde Islands (+238)
                  </option>
                  <option data-countrycode="CF" value="+236">
                    Central African Republic (+236)
                  </option>
                  <option data-countrycode="CL" value="+56">
                    Chile (+56)
                  </option>
                  <option data-countrycode="CN" value="+86">
                    China (+86)
                  </option>
                  <option data-countrycode="CO" value="+57">
                    Colombia (+57)
                  </option>
                  <option data-countrycode="KM" value="+269">
                    Comoros (+269)
                  </option>
                  <option data-countrycode="CG" value="+242">
                    Congo (+242)
                  </option>
                  <option data-countrycode="CK" value="+682">
                    Cook Islands (+682)
                  </option>
                  <option data-countrycode="CR" value="+506">
                    Costa Rica (+506)
                  </option>
                  <option data-countrycode="HR" value="+385">
                    Croatia (+385)
                  </option>
                  <option data-countrycode="CU" value="+53">
                    Cuba (+53)
                  </option>
                  <option data-countrycode="CY" value="+90392">
                    Cyprus North (+90392)
                  </option>
                  <option data-countrycode="CY" value="+357">
                    Cyprus South (+357)
                  </option>
                  <option data-countrycode="CZ" value="+42">
                    Czech Republic (+42)
                  </option>
                  <option data-countrycode="DK" value="+45">
                    Denmark (+45)
                  </option>
                  <option data-countrycode="DJ" value="+253">
                    Djibouti (+253)
                  </option>
                  <option data-countrycode="DM" value="+1809">
                    Dominica (+1809)
                  </option>
                  <option data-countrycode="DO" value="+1809">
                    Dominican Republic (+1809)
                  </option>
                  <option data-countrycode="EC" value="+593">
                    Ecuador (+593)
                  </option>
                  <option data-countrycode="EG" value="+20">
                    Egypt (+20)
                  </option>
                  <option data-countrycode="SV" value="+503">
                    El Salvador (+503)
                  </option>
                  <option data-countrycode="GQ" value="+240">
                    Equatorial Guinea (+240)
                  </option>
                  <option data-countrycode="ER" value="+291">
                    Eritrea (+291)
                  </option>
                  <option data-countrycode="EE" value="+372">
                    Estonia (+372)
                  </option>
                  <option data-countrycode="ET" value="+251">
                    Ethiopia (+251)
                  </option>
                  <option data-countrycode="FK" value="+500">
                    Falkland Islands (+500)
                  </option>
                  <option data-countrycode="FO" value="+298">
                    Faroe Islands (+298)
                  </option>
                  <option data-countrycode="FJ" value="+679">
                    Fiji (+679)
                  </option>
                  <option data-countrycode="FI" value="+358">
                    Finland (+358)
                  </option>
                  <option data-countrycode="FR" value="+33">
                    France (+33)
                  </option>
                  <option data-countrycode="GF" value="+594">
                    French Guiana (+594)
                  </option>
                  <option data-countrycode="PF" value="+689">
                    French Polynesia (+689)
                  </option>
                  <option data-countrycode="GA" value="+241">
                    Gabon (+241)
                  </option>
                  <option data-countrycode="GM" value="+220">
                    Gambia (+220)
                  </option>
                  <option data-countrycode="GE" value="+7880">
                    Georgia (+7880)
                  </option>
                  <option data-countrycode="DE" value="+49">
                    Germany (+49)
                  </option>
                  <option data-countrycode="GH" value="+233">
                    Ghana (+233)
                  </option>
                  <option data-countrycode="GI" value="+350">
                    Gibraltar (+350)
                  </option>
                  <option data-countrycode="GR" value="+30">
                    Greece (+30)
                  </option>
                  <option data-countrycode="GL" value="+299">
                    Greenland (+299)
                  </option>
                  <option data-countrycode="GD" value="+1473">
                    Grenada (+1473)
                  </option>
                  <option data-countrycode="GP" value="+590">
                    Guadeloupe (+590)
                  </option>
                  <option data-countrycode="GU" value="+671">
                    Guam (+671)
                  </option>
                  <option data-countrycode="GT" value="+502">
                    Guatemala (+502)
                  </option>
                  <option data-countrycode="GN" value="+224">
                    Guinea (+224)
                  </option>
                  <option data-countrycode="GW" value="+245">
                    Guinea - Bissau (+245)
                  </option>
                  <option data-countrycode="GY" value="+592">
                    Guyana (+592)
                  </option>
                  <option data-countrycode="HT" value="+509">
                    Haiti (+509)
                  </option>
                  <option data-countrycode="HN" value="+504">
                    Honduras (+504)
                  </option>
                  <option data-countrycode="HK" value="+852">
                    Hong Kong (+852)
                  </option>
                  <option data-countrycode="HU" value="+36">
                    Hungary (+36)
                  </option>
                  <option data-countrycode="IS" value="+354">
                    Iceland (+354)
                  </option>
                  <option data-countrycode="IN" value="+91">
                    India (+91)
                  </option>
                  <option data-countrycode="ID" value="+62">
                    Indonesia (+62)
                  </option>
                  <option data-countrycode="IR" value="+98">
                    Iran (+98)
                  </option>
                  <option data-countrycode="IQ" value="+964">
                    Iraq (+964)
                  </option>
                  <option data-countrycode="IE" value="+353">
                    Ireland (+353)
                  </option>
                  <option data-countrycode="IL" value="+972">
                    Israel (+972)
                  </option>
                  <option data-countrycode="IT" value="+39">
                    Italy (+39)
                  </option>
                  <option data-countrycode="JM" value="+1876">
                    Jamaica (+1876)
                  </option>
                  <option data-countrycode="JP" value="+81">
                    Japan (+81)
                  </option>
                  <option data-countrycode="JO" value="+962">
                    Jordan (+962)
                  </option>
                  <option data-countrycode="KZ" value="+7">
                    Kazakhstan (+7)
                  </option>
                  <option data-countrycode="KE" value="+254">
                    Kenya (+254)
                  </option>
                  <option data-countrycode="KI" value="+686">
                    Kiribati (+686)
                  </option>
                  <option data-countrycode="KP" value="+850">
                    Korea North (+850)
                  </option>
                  <option data-countrycode="KR" value="+82">
                    Korea South (+82)
                  </option>
                  <option data-countrycode="KW" value="+965">
                    Kuwait (+965)
                  </option>
                  <option data-countrycode="KG" value="+996">
                    Kyrgyzstan (+996)
                  </option>
                  <option data-countrycode="LA" value="+856">
                    Laos (+856)
                  </option>
                  <option data-countrycode="LV" value="+371">
                    Latvia (+371)
                  </option>
                  <option data-countrycode="LB" value="+961">
                    Lebanon (+961)
                  </option>
                  <option data-countrycode="LS" value="+266">
                    Lesotho (+266)
                  </option>
                  <option data-countrycode="LR" value="+231">
                    Liberia (+231)
                  </option>
                  <option data-countrycode="LY" value="+218">
                    Libya (+218)
                  </option>
                  <option data-countrycode="LI" value="+417">
                    Liechtenstein (+417)
                  </option>
                  <option data-countrycode="LT" value="+370">
                    Lithuania (+370)
                  </option>
                  <option data-countrycode="LU" value="+352">
                    Luxembourg (+352)
                  </option>
                  <option data-countrycode="MO" value="+853">
                    Macao (+853)
                  </option>
                  <option data-countrycode="MK" value="+389">
                    Macedonia (+389)
                  </option>
                  <option data-countrycode="MG" value="+261">
                    Madagascar (+261)
                  </option>
                  <option data-countrycode="MW" value="+265">
                    Malawi (+265)
                  </option>
                  <option data-countrycode="MY" value="+60">
                    Malaysia (+60)
                  </option>
                  <option data-countrycode="MV" value="+960">
                    Maldives (+960)
                  </option>
                  <option data-countrycode="ML" value="+223">
                    Mali (+223)
                  </option>
                  <option data-countrycode="MT" value="+356">
                    Malta (+356)
                  </option>
                  <option data-countrycode="MH" value="+692">
                    Marshall Islands (+692)
                  </option>
                  <option data-countrycode="MQ" value="+596">
                    Martinique (+596)
                  </option>
                  <option data-countrycode="MR" value="+222">
                    Mauritania (+222)
                  </option>
                  <option data-countrycode="YT" value="+269">
                    Mayotte (+269)
                  </option>
                  <option data-countrycode="MX" value="+52">
                    Mexico (+52)
                  </option>
                  <option data-countrycode="FM" value="+691">
                    Micronesia (+691)
                  </option>
                  <option data-countrycode="MD" value="+373">
                    Moldova (+373)
                  </option>
                  <option data-countrycode="MC" value="+377">
                    Monaco (+377)
                  </option>
                  <option data-countrycode="MN" value="+976">
                    Mongolia (+976)
                  </option>
                  <option data-countrycode="MS" value="+1664">
                    Montserrat (+1664)
                  </option>
                  <option data-countrycode="MA" value="+212">
                    Morocco (+212)
                  </option>
                  <option data-countrycode="MZ" value="+258">
                    Mozambique (+258)
                  </option>
                  <option data-countrycode="MN" value="+95">
                    Myanmar (+95)
                  </option>
                  <option data-countrycode="NA" value="+264">
                    Namibia (+264)
                  </option>
                  <option data-countrycode="NR" value="+674">
                    Nauru (+674)
                  </option>
                  <option data-countrycode="NP" value="+977">
                    Nepal (+977)
                  </option>
                  <option data-countrycode="NL" value="+31">
                    Netherlands (+31)
                  </option>
                  <option data-countrycode="NC" value="+687">
                    New Caledonia (+687)
                  </option>
                  <option data-countrycode="NZ" value="+64">
                    New Zealand (+64)
                  </option>
                  <option data-countrycode="NI" value="+505">
                    Nicaragua (+505)
                  </option>
                  <option data-countrycode="NE" value="+227">
                    Niger (+227)
                  </option>
                  <option data-countrycode="NG" value="+234">
                    Nigeria (+234)
                  </option>
                  <option data-countrycode="NU" value="+683">
                    Niue (+683)
                  </option>
                  <option data-countrycode="NF" value="+672">
                    Norfolk Islands (+672)
                  </option>
                  <option data-countrycode="NP" value="+670">
                    Northern Marianas (+670)
                  </option>
                  <option data-countrycode="NO" value="+47">
                    Norway (+47)
                  </option>
                  <option data-countrycode="OM" value="+968">
                    Oman (+968)
                  </option>
                  <option data-countrycode="PW" value="+680">
                    Palau (+680)
                  </option>
                  <option data-countrycode="PA" value="+507">
                    Panama (+507)
                  </option>
                  <option data-countrycode="PG" value="+675">
                    Papua New Guinea (+675)
                  </option>
                  <option data-countrycode="PY" value="+595">
                    Paraguay (+595)
                  </option>
                  <option data-countrycode="PE" value="+51">
                    Peru (+51)
                  </option>
                  <option data-countrycode="PH" value="+63">
                    Philippines (+63)
                  </option>
                  <option data-countrycode="PL" value="+48">
                    Poland (+48)
                  </option>
                  <option data-countrycode="PT" value="+351">
                    Portugal (+351)
                  </option>
                  <option data-countrycode="PR" value="+1787">
                    Puerto Rico (+1787)
                  </option>
                  <option data-countrycode="QA" value="+974">
                    Qatar (+974)
                  </option>
                  <option data-countrycode="RE" value="+262">
                    Reunion (+262)
                  </option>
                  <option data-countrycode="RO" value="+40">
                    Romania (+40)
                  </option>
                  <option data-countrycode="RU" value="+7">
                    Russia (+7)
                  </option>
                  <option data-countrycode="RW" value="+250">
                    Rwanda (+250)
                  </option>
                  <option data-countrycode="SM" value="+378">
                    San Marino (+378)
                  </option>
                  <option data-countrycode="ST" value="+239">
                    Sao Tome &amp; Principe (+239)
                  </option>
                  <option data-countrycode="SA" value="+966">
                    Saudi Arabia (+966)
                  </option>
                  <option data-countrycode="SN" value="+221">
                    Senegal (+221)
                  </option>
                  <option data-countrycode="CS" value="+381">
                    Serbia (+381)
                  </option>
                  <option data-countrycode="SC" value="+248">
                    Seychelles (+248)
                  </option>
                  <option data-countrycode="SL" value="+232">
                    Sierra Leone (+232)
                  </option>
                  <option data-countrycode="SG" value="+65">
                    Singapore (+65)
                  </option>
                  <option data-countrycode="SK" value="+421">
                    Slovak Republic (+421)
                  </option>
                  <option data-countrycode="SI" value="+386">
                    Slovenia (+386)
                  </option>
                  <option data-countrycode="SB" value="+677">
                    Solomon Islands (+677)
                  </option>
                  <option data-countrycode="SO" value="+252">
                    Somalia (+252)
                  </option>
                  <option data-countrycode="ZA" value="+27">
                    South Africa (+27)
                  </option>
                  <option data-countrycode="ES" value="+34">
                    Spain (+34)
                  </option>
                  <option data-countrycode="LK" value="+94">
                    Sri Lanka (+94)
                  </option>
                  <option data-countrycode="SH" value="+290">
                    St. Helena (+290)
                  </option>
                  <option data-countrycode="KN" value="+1869">
                    St. Kitts (+1869)
                  </option>
                  <option data-countrycode="SC" value="+1758">
                    St. Lucia (+1758)
                  </option>
                  <option data-countrycode="SD" value="+249">
                    Sudan (+249)
                  </option>
                  <option data-countrycode="SR" value="+597">
                    Suriname (+597)
                  </option>
                  <option data-countrycode="SZ" value="+268">
                    Swaziland (+268)
                  </option>
                  <option data-countrycode="SE" value="+46">
                    Sweden (+46)
                  </option>
                  <option data-countrycode="CH" value="+41">
                    Switzerland (+41)
                  </option>
                  <option data-countrycode="SI" value="+963">
                    Syria (+963)
                  </option>
                  <option data-countrycode="TW" value="+886">
                    Taiwan (+886)
                  </option>
                  <option data-countrycode="TJ" value="+7">
                    Tajikstan (+7)
                  </option>
                  <option data-countrycode="TH" value="+66">
                    Thailand (+66)
                  </option>
                  <option data-countrycode="TG" value="+228">
                    Togo (+228)
                  </option>
                  <option data-countrycode="TO" value="+676">
                    Tonga (+676)
                  </option>
                  <option data-countrycode="TT" value="+1868">
                    Trinidad &amp; Tobago (+1868)
                  </option>
                  <option data-countrycode="TN" value="+216">
                    Tunisia (+216)
                  </option>
                  <option data-countrycode="TR" value="+90">
                    Turkey (+90)
                  </option>
                  <option data-countrycode="TM" value="+7">
                    Turkmenistan (+7)
                  </option>
                  <option data-countrycode="TM" value="+993">
                    Turkmenistan (+993)
                  </option>
                  <option data-countrycode="TC" value="+1649">
                    Turks &amp; Caicos Islands (+1649)
                  </option>
                  <option data-countrycode="TV" value="+688">
                    Tuvalu (+688)
                  </option>
                  <option data-countrycode="UG" value="+256">
                    Uganda (+256)
                  </option>
                  <option data-countrycode="GB" value="+44">
                    UK (+44)
                  </option>
                  <option data-countrycode="UA" value="+380">
                    Ukraine (+380)
                  </option>
                  <option data-countrycode="AE" value="+971">
                    United Arab Emirates (+971)
                  </option>
                  <option data-countrycode="UY" value="+598">
                    Uruguay (+598)
                  </option>
                  <option data-countrycode="US" value="+1">
                    USA (+1)
                  </option>
                  <option data-countrycode="UZ" value="+7">
                    Uzbekistan (+7)
                  </option>
                  <option data-countrycode="VU" value="+678">
                    Vanuatu (+678)
                  </option>
                  <option data-countrycode="VA" value="+379">
                    Vatican City (+379)
                  </option>
                  <option data-countrycode="VE" value="+58">
                    Venezuela (+58)
                  </option>
                  <option data-countrycode="VN" value="+84">
                    Vietnam (+84)
                  </option>
                  <option data-countrycode="VG" value="+84">
                    Virgin Islands - British (+1284)
                  </option>
                  <option data-countrycode="VI" value="+84">
                    Virgin Islands - US (+1340)
                  </option>
                  <option data-countrycode="WF" value="+681">
                    Wallis &amp; Futuna (+681)
                  </option>
                  <option data-countrycode="YE" value="+969">
                    Yemen (North)(+969)
                  </option>
                  <option data-countrycode="YE" value="+967">
                    Yemen (South)(+967)
                  </option>
                  <option data-countrycode="ZM" value="+260">
                    Zambia (+260)
                  </option>
                  <option data-countrycode="ZW" value="+263">
                    Zimbabwe (+263)
                  </option>
                </optgroup>
              </select>
              <input
                type="text"
                id="mobileno"
                className="form-control"
                placeholder="Enter your number..."
              />
              <a className="btn btn-danger signup" style={{cursor:"pointer"}}>Sign in</a>
            </div>
            <p className="text-white">
              <u style={{cursor:'pointer'}} onClick={() => navigate("/login")}
                  >
                {" "}
                Not a Member?{" "}
                <Box
                  component={"span"}
                  className="home-signup"
                >
                  Sign up
                </Box>
              </u>
            </p>
          </div>
        </section>
      </Container>
      {/* footer section */}
      <FooterV2 />
    </React.Fragment>
  );
}
