import axios from "axios";
import { useSelector } from "react-redux";
import {store} from "./app/redux/store"
const server = process.env.REACT_APP_BASE_URL
// const userData = useSelector((state) => state.userData.userData);

console.log(server);
const instance = axios.create({
  baseURL: server + "/api"
});

instance.interceptors.request.use((request) => {
  let user = localStorage.getItem("token_id");
  const state = store.getState();
  console.log("objectss" ,state)
  const userToken = state.userData?.userData?.token || user;
  console.log(userToken ,"objectss")
  request.headers = {
    Accept: "application/json, text/plain, */*",
    Authorization: `Bearer ${userToken}`,
    "X-Custom-Origin":"web"
  };
  return request;
});

instance.interceptors.response.use((response) => {
  if (response) {
    return response;
  }
},
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;