import {
  Box,
  Container,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from "@mui/material";
import React, { useEffect } from "react";
import Header2 from "../../../../layout/dashboard/header/header2";
import Colors from "../../../../assets/styles";
import FooterV2 from "../../../../layout/footerV2";
import { useLocation } from "react-router-dom";

export default function TermsAndCondition() { 
   const location =useLocation()
   useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [location]);

  
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Header2 />

        <Container sx={{ py: 10, maxWidth: "1300px" }}>
          {/* Privacy Policy Header */}
          <Typography
            variant="h4"
            fontWeight="bold"
            mb={4}
            sx={{ fontFamily: "Poppins", textAlign: "center" }}
          >
            Terms And Condition
          </Typography>

          {/* Privacy Policy Content */}
          <Box sx={{ color: "black", lineHeight: 1.75, fontFamily: "Poppins" }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
            >
              Let’s Eat Ltd. Consumers
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
            >
              <strong>Effective:</strong> March 22th, 2020
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "bold",
              }}
            >
              PLEASE READ THE TERMS AND CONDITIONS CAREFULLY. THE TERMS AND
              CONDITIONS (“AGREEMENT") CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU
              AND LET’S EAT LTD. (“LET’S EAT," “WE," “US," OR “OUR"). SECTION 11
              OF THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS THAT
              YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING,
              WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE
              THE EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, SECTION 11
              SETS FORTH OUR ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED
              EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING
              AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION
              AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND
              SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A CLAIMANTIN
              A REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING
              YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY
              TRIAL ON YOUR CLAIMS. THE ARBITRATION AGREEMENT COULD AFFECT YOUR
              RIGHT TO PARTICIPATE IN PENDING PROPOSED REPRESENTATIVE ACTIONS.
              PLEASE SEE SECTION 11 FOR MORE INFORMATION REGARDING THIS
              ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS OF THIS ARBITRATION
              AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              1. Acceptance of this Agreement
            </Typography>

            {/* Information Collection */}

            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              The Let’s Eat provides an online marketplace connection, using
              web-based technology that connects you and other consumers and
              restaurants and/or other businesses. The Let’s Eat’s software(the
              “Software") permits consumers to place orders for food and/or
              other goods from various restaurants and businesses. Once such
              orders are made, the Let’s Eat’s Software notifies us and Merchant
              that a delivery opportunity is available and the Software
              facilitates completion of the delivery to the consumer. The Let’s
              Eat is not a restaurant or food preparation business. If you
              access the website located at http://www.LetsEat.ky , install or
              use Let’s Eat’s mobile application, install or use any other
              Software supplied by Let’s Eat, or access any information,
              function, or service available or enabled by Let’s Eat (each, a
              “Service" and collectively, the “Services"), or complete the Let’s
              Eat account registration process, you, your heirs, assigns, and
              successors (collectively, “you" or “your") hereby represent and
              warrant that:
            </Typography>

            <List
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",

                listStyleType: "none",
                "& .MuiListItem-root": { display: "list-item" },
              }}
            >
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="you have read, understand, and agree to be bound by this Agreement;"
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="you are of legal age in the jurisdiction in which you reside to form a binding contract with Let’s Eat the “Minimum Age ; and"
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="you have the authority to enter into the Agreement personally and, if applicable, on behalf of any organization on whose behalf you have created an account and to bind such organization to the Agreement. The terms User and  Users refer to all individuals and other persons who access or use the Services, including, without limitation, any organizations that register accounts or otherwise access or use the Services through their respective representatives. Except as otherwise provided in this Agreement, if you do not agree to be bound by the Agreement, you may not access or use Let’s Eat’s Services."
                />
              </ListItem>
            </List>

            {/* Information from Publicly Available Sources */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              2. Modifications
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "bold",
              }}
            >
              Subject to Section 11 of this Agreement, Let’s Eat reserves the
              right to modify the terms and conditions of this Agreement or its
              policies relating to the Software or Services at any time,
              effective upon posting of an updated version of this Agreement
              through the Services. You should regularly review this Agreement,
              as your continued use of the Services after any such changes
              constitutes your agreement to such changes.
            </Typography>

            {/* Use of Information */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              3. Additional Terms and Policies
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
              paragraph
            >
              By using the Services, you agree to be bound by this Agreement and
              acknowledge and agree to the collection, use, and disclosure of
              your personal information in accordance with Let’s Eat’s{" "}
              <strong sx={{ color: Colors.primary }}> Privacy Policy</strong>{" "}
              which is incorporated in this Agreement by reference. Certain
              features of our Services may be subject to additional terms and
              conditions, which are incorporated herein by reference.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              4. Rules and Prohibitions
            </Typography>
            <List
              sx={{
                listStyleType: "none",
                "& .MuiListItem-root": { display: "list-item" },
              }}
            >
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="a) You will only use the Services for lawful purposes; you will not use the Services for sending or storing any unlawful material or for deceptive or fraudulent purposes; and you will not engage in conduct that harms other Users, Let’s Eat employees, or our community.:"
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="b) You will only use the Services in accordance with all applicable laws, including copyrights, trade secrets, or other rights of any third party, including privacy or publicity rights."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="c) You will only access the Services using means explicitly authorized by Let’s Eat."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="d )You will not use another User’s account, impersonate any person or entity, or forge or manipulate headers or identifiers to disguise the origin of any content transmitted through the Services."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="e) You will not use the Services to cause nuisance, annoyance or inconvenience."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="f) You will not use the Services, or any content accessible through the Services, for any commercial purpose, including but not limited to contacting, advertising to, soliciting or selling to, any Merchant, or user, unless Let’s Eat has given you prior permission to do so in writing."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="g) You will not copy or distribute the Software or any content displayed through the Services, including Merchants’ (as defined below) menu content and reviews, for republication in any format or media."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="h) You will not compile, directly or indirectly, any content displayed through the Services except for your personal, noncommercial use."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="i) The information you provide to us when you register an account or otherwise communicate with us is accurate, you will promptly notify us of any changes to such information, and you will provide us with whatever proof of identity we may reasonably request."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="j) You will keep secure and confidential your account password or any identification credentials we provide you which allows access to the Services."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="k) You will use the Software and Services only for your own use and will not directly or indirectly resell, license or transfer the Software, Services or content displayed by the Services to a third party."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="l) You will not use the Services in any way that could damage, disable, overburden or impair any Let’s Eat server, or the networks connected to any Let’s Eat server."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="m) You will not use the Services in any way that could damage, disable, overburden or impair any Let’s Eat server, or the networks connected to any Let’s Eat server.
"
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="n) You will not probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures Let’s Eat may use to prevent or restrict access to the Services or use of the Services or the content therein."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="o) You will not deep-link to the Let’s Eat website or access the Let’s Eat website manually or with any robot, spider, web crawler, extraction Software, automated process and/or device to scrape, copy, index, frame, or monitor any portion of the Let’s Eat website or any content on the Let’s Eat website."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="p) You will not conduct any systematic retrieval of data or other content from the Services."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="q) You will not try to harm other Users, Let’s Eat, or the Services in any way whatsoever."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="r) You will report any errors, bugs, unauthorized access methodologies or any breach of our intellectual property rights that you uncover in your use of the Services."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="s) You will not abuse our promotional or credit code system, including by redeeming multiple coupons at once or by opening multiple accounts to benefit from offers available only to first time users."
                />
              </ListItem>
              <ListItem sx={{ padding: "0 !important" }}>
                <ListItemText
                  sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                  primary="t) You will not attempt to undertake any of the foregoing."
                />
              </ListItem>
            </List>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              5. Merchants Are Independent
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              You understand and agree that Let’s Eat provides a technology
              platform connecting you with independent food service providers
              and others that provide the products offered through the Services
              (“Merchants"). You acknowledge and agree that Let’s Eat does not
              itself prepare food and has no responsibility or liability for the
              acts or omissions of any Merchant. Let’s Eat is not the retailer
              of any products offered by Merchants. Let’s Eat provides a
              technology platform facilitating the transmission of orders by
              Users to Merchants for pickup or delivery by us. Let’s Eat will
              not assess or guarantee the suitability, legality or ability of
              any Merchant. You agree that Let’s Eat is not responsible for the
              Merchants’ food preparation or the safety of the food, and does
              not verify Merchants’ compliance with applicable laws or
              regulations. Let’s Eat has no responsibility or liability for acts
              or omissions by any Merchant. You agree that the goods that you
              purchase will be prepared by the Merchant you have selected, that
              title to the goods passes from the Merchant to you at the
              Merchant’s location. You agree that Let’s Eat holds title to or
              acquires any ownership interest in any goods that you order
              through the Services.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              6. User Account
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              You may be required to register for an account to use parts of the
              Services. You must provide accurate, current, and complete
              information during the registration process and at all other times
              when you use the Services, and to update the information to keep
              it accurate, current, and complete. You are the sole authorized
              user of any account you create through the Services. You are
              solely and fully responsible for all activities that occur under
              your password or account. You agree that you shall monitor your
              account to prevent use by minors, and you will accept full
              responsibility for any unauthorized use of your password or your
              account. You may not authorize others to use your User status, and
              you may not assign or otherwise transfer your User account to any
              other person or entity. Should you suspect that any unauthorized
              party may be using your password or account, you will notify Let’s
              Eat immediately. Let’s Eat will not be liable and you may be
              liable for losses, damages, liability, expenses, and fees incurred
              by Let’s Eat or a third party arising from someone else using your
              account, regardless of whether you have notified us of such
              unauthorized use. If you provide any information that is untrue,
              inaccurate, not current, or incomplete, or Let’s Eat has
              reasonable grounds to suspect that such information is untrue,
              inaccurate, not current, or incomplete, Let’s Eat has the right to
              suspend or terminate your account and refuse any and all current
              or future use of the Services (or any portion thereof). You agree
              not to create an account or use the Services if you have been
              previously removed by Let’s Eat, or if you have been previously
              banned from use of the Services.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              7. User Content
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              <strong>(a) User Content:</strong> Let’s Eat may provide you with
              interactive opportunities through the Services, including, by way
              of example, the ability to post User ratings and reviews
              (collectively, “User Content"). You represent and warrant that you
              are the owner of, or otherwise have the right to provide, all User
              Content that you submit, post and/or otherwise transmit through
              the Services. You hereby grant Let’s Eat a perpetual, irrevocable,
              transferable, fully paid, royalty-free, non-exclusive, worldwide,
              fully sublicenseable right and license to use, copy, display,
              publish, modify, remove, publicly perform, translate, create
              derivative works, distribute and/or otherwise use the User Content
              in connection with Let’s Eat’s business and in all forms now known
              or hereafter invented (“Uses"), without notification to and/or
              approval by you. You further grant Let’s Eat a license to use your
              username and/or other User profile information, including without
              limitation your ratings history, to attribute User Content to you
              in connection with such Uses, without notification or approval by
              you. You agree that this license includes the right for other
              Users to access and use your User Content in conjunction with
              participation in the Services and as permitted through the
              functionality of the Services. In the interest of clarity, the
              license granted to Let’s Eat herein shall survive termination of
              the Services or your account. Let’s Eat reserves the right in its
              sole discretion to remove or disable access to any User Content
              from the Services, suspend or terminate your account at any time,
              or pursue any other remedy or relief available under equity or law
              if you post any User Content that violates this Agreement or we
              consider to be objectionable for any reason. You agree that Let’s
              Eat may monitor and/or delete your User Content (but does not
              assume the obligation) for any reason in Let’s Eat’s sole
              discretion. Let’s Eat may also access, read, preserve, and
              disclose any information as Let’s Eat reasonably believes is
              necessary to (i) satisfy any applicable law, regulation, legal
              process, or governmental request, (ii) enforce this Agreement,
              including investigation of potential violations hereof, (iii)
              detect, prevent, or otherwise address fraud, security, or
              technical issues, (iv) respond to User support requests, or (v)
              protect the rights, property or safety of Let’s Eat, its users and
              the public. <br />
              <strong>(b) Feedback:</strong> You agree that any submission of
              any ideas, suggestions, and/or proposals to Let’s Eat through its
              suggestion, feedback, wiki, forum or similar pages (“Feedback") is
              at your own risk and that Let’s Eat has no obligations (including
              without limitation, obligations of confidentiality) with respect
              to such Feedback. You represent and warrant that you have all
              rights necessary to submit the Feedback and you hereby grant to
              Let’s Eat a perpetual, irrevocable, transferable, fully paid,
              royalty-free, non-exclusive, worldwide, fully sublicenseable right
              and license to use, copy, display, publish, modify, remove,
              publicly perform, translate, create derivative works, distribute
              and/or otherwise use such Feedback.
              <br />
              <strong>(c) Ratings and Reviews:</strong> To the extent that you
              are asked to rate and post reviews of Merchants or other
              businesses (“Ratings" and “Reviews"), such Ratings and Reviews are
              considered User Content and are governed by this Agreement.
              Ratings and Reviews are not endorsed by Let’s Eat and do not
              represent the views of Let’s Eat or its affiliates. Let’s Eat
              shall have no liability for Ratings and Reviews or for any claims
              for economic loss resulting from such Ratings and Reviews. Because
              we strive to maintain a high level of integrity with respect to
              Ratings and Reviews posted or otherwise made available through the
              Services, you agree that: (i) you will base any Rating or Review
              on first-hand experience with the Merchant or business; (ii) you
              will not provide a Rating or Review for any Merchant or business
              for which you have an ownership interest, employment relationship
              or other affiliation or for any of that Let’s Eat’s competitors;
              (iii) you will not submit a Rating or Review in exchange for
              payment, free food items, or other benefits from a Merchant or
              business; and (iv) your review will comply with the terms of this
              Agreement. If we determine, in our sole discretion, that any
              Rating or Review could diminish the integrity of the Ratings and
              Reviews or otherwise violates this Agreement, we may remove such
              User Content without notice.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              8. Communications with Let’s Eat
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              By creating a Let’s Eat account, you electronically agree to
              accept and receive communications from Let’s Eator third parties
              providing services to Let’s Eat including via email, text message,
              calls, and push notifications to the cellular telephone number you
              provided to Let’s Eat. You understand and agree that you may
              receive communications generated by automatic telephone dialing
              systems and/or which will deliver prerecorded messages sent by or
              on behalf of Let’s Eat and/or its affiliated companies, including
              but not limited to communications concerning orders placed through
              your account on the Services. Message and data rates may apply. If
              you do not wish to receive promotional emails, text messages, or
              other communications, you may change your notification preferences
              by accessing the Settings in your account. You may also opt-out of
              receiving text messages from Let’s Eat by replying “STOP" from the
              mobile device receiving the messages.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              9. Intellectual Property Ownership
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
            >
              Let’s Eat alone (and its licensors, where applicable) shall own
              all right, title and interest, including all related intellectual
              property rights, in and to the Software and the Services. This
              Agreement is not a sale and does not convey to you any rights of
              ownership in or related to the Software or the Services, or any
              intellectual property rights owned by Let’s Eat. Let’s Eat name,
              Let’s Eat logo, and the product names associated with the Software
              and Services are trademarks of Let’s Eat or third parties, and no
              right or license is granted to use them. You agree that you will
              not remove, alter or obscure any copyright, trademark, service
              mark or other proprietary rights notices incorporated in or
              accompanying the Software or the Services.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              10. Payment Terms
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              <strong>(a) Prices: </strong> You understand that: (a) the prices
              for menu items displayed through the Services may differ from the
              prices offered or published by Merchants for the same menu items
              and/or from prices available at third-party websites and that such
              prices may not be the lowest prices at which the menu items are
              sold; (b) Let’s Eat has no obligation to itemize its costs,
              profits or margins when publishing such prices; and (c) Let’s Eat
              reserves the right to change such prices at any time, at its
              discretion. You are liable for all transaction taxes on the
              Services provided under this Agreement (other than, if applicable,
              taxes based on Let’s Eat’s income). Payment will be processed by
              Let’s Eat, using the preferred payment method designated in your
              account.
              <br />
              <strong>(b) No Refunds: </strong>
              Charges paid by you for completed and delivered orders are final
              and non-refundable. Let’s Eat has no obligation to provide refunds
              or credits, but may grant them, in each case in Let’s Eat’s sole
              discretion.
              <br />
              <strong> (c) Promotional Offers and Credits: </strong>
              Let’s Eat, at its sole discretion, may make promotional offers
              with different features and different rates to any User. These
              promotional offers are subject to the terms of this Agreement and
              may be valid only for certain Users as indicated in the offer. You
              agree that promotional offers: (i) may only be used by the
              intended audience, for the intended purpose, and in a lawful
              manner; (ii) may not be duplicated, sold or transferred in any
              manner, or made available to the general public, unless expressly
              permitted by Let’s Eat; (iii) are subject to the specific terms
              that Let’s Eat establishes for such promotional offer; (iv) cannot
              be redeemed for cash or cash equivalent; (v) are not valid for use
              after the date indicated in the offer or in Let’s Eat’s Terms and
              Conditions for Promotional Offers and Credits. Let’s Eat reserves
              the right to withhold or deduct credits or benefits obtained
              through a promotion in the event that Let’s Eat determines or
              believes that the redemption of the promotion or receipt of the
              credit or benefit was in error, fraudulent, illegal, or in
              violation of the applicable promotion terms or this Agreement.
              Let’s Eat reserves the right to modify or cancel an offer at any
              time. Let’s Eat may also offer gratuitous credits, which can be
              used for the Services. Any credit issued by Let’s Eat is valid for
              6 months from the date of issue except to the extent prohibited
              under applicable law and may not be redeemed for cash or cash
              equivalent. Upon expiration, credits will be removed from your
              account. Expired credits are no longer redeemable and cannot be
              used towards any order.
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              In addition, the following terms and conditions to promotional
              offers and credits also apply:
            </Typography>

            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              To qualify for and receive any promotion and/or credit
              (collectively “Offer(s)"), you must meet the requirements
              indicated, as applicable, in the Offer, in the user’s Let’s Eat
              account, and those listed in this Section 10. You must have a
              valid Let’s Eat account with a valid form of accepted payment on
              file to take advantage of an Offer. Offer must be used when
              placing a order, but the Offer may not be applied if all
              conditions are not met after fulfillment of any order applying the
              Offer. Offers can only be redeemed for Let’s Eat orders on
              LetsEat.ky or on the Let’s Eat app with the latest version, and
              within Let’s Eat service areas and service hours. For Offers
              available only to new customers or new subscribers, as applicable,
              customers will be disqualified and will not be entitled to receive
              the Offer if Let’s Eat has a record of their name, email, phone
              number, or credit card having being used for a prior Let’s Eat
              order or being linked to an existing active or inactive Let’s Eat
              account. All deliveries are subject to availability. Customers
              must be 18 or older to order alcohol. Offers are not transferable
              and may not be auctioned, traded, copied, transferred, bartered,
              modified or sold. Offers cannot be applied retroactively for prior
              purchases. Offers cannot be combined unless otherwise indicated.
              Void where restricted or prohibited by law. Let’s computer is the
              official date/time keeping device for all Offers. You are
              responsible for paying any applicable sales tax, if any, related
              to the use of an Offer; Let’s Eat has no obligation for payment of
              any tax in conjunction with the distribution or use of any Offer.
              Promotional codes (“promo codes") will expire on the date
              indicated in the communication to you with the promo code; if no
              date is specified, the promo code will expire (a) for promo codes
              contained in mail or email, 30 days after the date the mail or
              email was sent and (b) for promo codes published in any form
              (including online), 30 days after the date on which it was last
              displayed to consumers. To use the promo code and apply it to an
              order, you should follow the instructions in the Offer. Promo
              codes for credits cannot be applied towards taxes, if applicable,
              or fees other than delivery fees (including but not limited to
              service fees, small order fees, and surge fees). Promo codes may
              be limited to a one-time use as indicated in the offer.
              One-time-use offers apply to charges associated with an order from
              a single merchant location only. Promo codes cannot be applied to
              the purchase of alcohol. Unless otherwise indicated in offer, all
              promo codes are limited to one per person. Other fees (including
              but not limited to service fees, small order fees, and surge
              fees), any applicable taxes (if any), and gratuity still apply.
              <br />
              Discount codes cannot be applied toward the purchase of alcohol.
              Pickup discount codes are applicable only for pickup orders;
              pickup only available at participating merchants. Delivery
              discount codes are applicable only to delivery orders. Let’s Eat
              credits can only be redeemed for Let’s Eat orders on LetsEat.ky or
              on the Let’s Eat app with the latest version, and within Let’s Eat
              service areas and service hours. Credits cannot be applied towards
              alcohol purchases. Credits are applied toward order subtotals
              (excluding gratuity and alcohol purchases). Credits may not be
              applied with any other Offer.
            </Typography>
           
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              <strong>(d) Fees for Services:</strong> Let’s Eat may change the
              fees for our Services as we deem necessary or appropriate for our
              business, including but not limited to Delivery Fees, Service
              Fees, Small Order Fees, and Surge Fees.
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              <strong>(e) Referral Program:</strong> Under the Let’s Eat
              Referral Program (the “Referral Program"), Let’s Eat offers its
              registered Users in good standing the opportunity to earn
              gratuitous Let’s Eat credits as promotional rewards by inviting
              their eligible friends to register as new Let’s Eat Users and
              place their initial order through the Service by using a unique
              referral ID link (“Personal Link"). For each qualified referral
              (subject to the terms herein) generated through a User’s Personal
              Link, the User may receive a gratuitous credit as specified on
              Let’s Eat’s Referral Program page. You agree that we may change
              the terms and conditions of the Referral Program or terminate the
              Referral Program at any time.
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              In addition, the following terms and conditions to Let’s Eat’s
              referral program also apply:
            </Typography>

            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              Existing customers ("Referrer") who refer friends and family as
              new customers ("Customer") to the Let’s Eat platform, may be
              eligible to receive referral credits (“Credit(s)"). Customer must
              sign up using the unique link ("Link") provided to them by
              Referrer. The Link must be provided to Customer through email,
              text, or the Share Your Link option on the app. Referrer is
              prohibited from posting referral links on sites including, but not
              limited to Google, Ecaytrade, Twitter, Facebook, Instagram and
              other similar publicly accessible sites. Let’s Eat reserves the
              right to deactivate or invalidate any Credits obtained through
              these channels. Referrer will only receive Credits for the first
              25 Customers they refer. Customer will receive one Credit when
              they sign up through the Link, are one of the 25 Customers, and
              meet the requirements as indicated in the offer. Let’s Eat
              reserves the right to deactivate or invalidate any Credits
              obtained by either Customer or Referrer in excess of these
              amounts. Credit amounts will be stated in Referrer's Let’s account
              and in the offer received by Customer. Customer will be
              disqualified and will not receive Credits if Let’s Eat determines
              that Customer has previously created a Let’s Eat account. Let’s
              Eat reserves the right to withhold or invalidate Credit obtained
              through the Referral Program if Let’s Eat determines or believes
              that the Credit was received through error, fraudulent, illegal,
              or in violation of these terms and conditions or any other
              applicable agreement between you and Let’s Eat. Credits will be
              applied automatically at checkout to order amount (excluding
              gratuity). Credits cannot be applied to alcohol products. Credits
              can only be redeemed for Let’s Eat orders on LetsEat.ky or on the
              Let’s Eat app with the latest version, and within Let’s Eat
              service areas and delivery hours. Credits cannot be redeemed for
              cash or cash equivalent. Credits are not transferable and may not
              be auctioned, traded, bartered, or sold. Credits may not be used
              in conjunction with select promotions. Let’s Eat reserves the
              right to withhold or invalidate Credit obtained through the
              Referral Program if Let’s Eat determines or believes that the
              Credit was received through error, fraudulent, illegal, or in
              violation of these terms and conditions or any other applicable
              agreement between you and Let’s Eat.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins" }}
            >
              11. Dispute Resolution.
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "bold",
              }}
            >
              PLEASE READ THE FOLLOWING SECTION CAREFULLY. IT REQUIRES YOU TO
              ARBITRATE DISPUTES WITH THE LET’S EAT AND LIMITS THE MANNER IN
              WHICH YOU CAN SEEK RELIEF. THIS SECTION 11 OF THIS AGREEMENT SHALL
              BE REFERRED TO AS THE “ARBITRATION AGREEMENT".
            </Typography>

            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              <strong>1)Scope of Arbitration Agreement:</strong>Any dispute,
              controversy or claim arising out of, relating to or in connection
              with this contract, including the breach, termination or validity
              thereof, shall be finally resolved by binding arbitration, rather
              than in court, except that (1) you may assert small claims in
              Summary Court if your claims qualify, so long as the matter
              remains in such court and advances only on an individual
              (non-representative) basis; and (2) you or Let’s Eat may seek
              equitable relief in court for infringement or other misuse of
              intellectual property rights (such as trademarks, trade dress,
              domain names, trade secrets, copyrights, and patents). This
              Arbitration Agreement shall apply to all disputes arising from or
              relating to the subject matter of this Agreement or the
              relationship between the parties and their personnel. This
              Arbitration Agreement shall apply, without limitation, to all
              claims that arose or were asserted before the Effective Date of
              this Agreement. CLAIMS MAY HAVE BEEN FILED AGAINST LET’S EAT—AND
              OTHERS MAY BE FILED IN THE FUTURE—THAT ATTEMPT TO ASSERT CLAIMS IN
              A REPRESENTATIVE ACTION, AND BY ACCEPTING THIS ARBITRATION
              AGREEMENT YOU ELECT NOT TO PARTICIPATE IN SUCH CASES. IF YOU AGREE
              TO ARBITRATION WITH LET’S EAT, YOU ARE AGREEING IN ADVANCE THAT
              YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER
              RELIEF IN ANY SUCH CLASS, COLLECTIVE, AND/OR REPRESENTATIVE
              LAWSUIT. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR
              CLAIMS AGAINST LET’S EAT IN AN INDIVIDUAL ARBITRATION PROCEEDING.
              IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER
              RELIEF BY AN ARBITRATOR.
              <br />
              <strong>2) Arbitration Rules and Forum Arbitration </strong>
              will be conducted by the Cayman Islands Association of Mediators
              and Arbitrators under its then-current arbitration rules and
              pursuant to the terms of this Agreement. <br />
              <strong>3)Arbitrator Powers</strong> The arbitrator, and not any
              court or agency, shall have exclusive authority to resolve any
              dispute relating to the interpretation, applicability,
              enforceability or formation of this Arbitration Agreement
              including, but not limited to any claim that all or any part of
              this Arbitration Agreement is void or voidable. The arbitration
              will decide the rights and liabilities, if any, of you and Let’s
              Eat. The arbitration proceeding will not be consolidated with any
              other matters or joined with any other proceedings or parties. The
              arbitrator will have the authority to grant motions dispositive of
              all or part of any claim or dispute. The arbitrator will have the
              authority to award monetary damages and to grant any non-monetary
              remedy or relief available to an individual under applicable law,
              the arbitral forum’s rules, and this Agreement (including this
              Arbitration Agreement). The arbitrator will issue a written
              statement of decision describing the essential findings and
              conclusions on which any award (or decision not to render an
              award) is based, including the calculation of any damages awarded.
              The arbitrator shall follow the applicable law. The arbitrator has
              the same authority to award relief on an individual basis that a
              judge in a court of law would have. The arbitrator’s decision is
              final and binding on you and Let’s Eat.
              <br />
              <strong>
                {" "}
                Waiver of Class or Consolidated Actions; Severability:
              </strong>{" "}
              YOU AND LET’S EAT AGREE TO WAIVE ANY RIGHT TO RESOLVE CLAIMS
              WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT ON A REPRESENTATIVE
              BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
              ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS
              AND NOT ON A REPRESENTATIVE BASIS. CLAIMS OF MORE THAN ONE
              MERCHANT CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED
              WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver
              of class or consolidated actions is deemed invalid or
              unenforceable with respect to a particular claim or dispute,
              neither you nor Let’s Eat is entitled to arbitration of such claim
              or dispute. Instead, all such claims and disputes will then be
              resolved in a court as set forth in Section 22, and all other
              provisions of this Section 11 (Dispute Resolution) shall remain in
              force. If any provision of this Section 11 is adjudged to be void
              or otherwise unenforceable, in whole or in part, the void or
              unenforceable provision shall be severed and such adjudication
              shall not affect the validity of the remainder of this Section 11.{" "}
              <br />
              <strong>5) Opt Out:</strong> You may opt out of this Arbitration
              Agreement. If you do so, neither you nor Let’s Eat can force the
              other to arbitrate as a result of this Agreement. To opt out, you
              must notify Let’s Eat in writing of your intention to opt out by
              sending a letter to: Let’s Eat Ltd. 11 Ashgo Street, George Town,
              Grand Cayman KY1-1101. Any attempt to opt out by email will be
              ineffective. To be effective, your opt-out notice must be
              postmarked no later than 30 days after your first becoming subject
              to this Arbitration Agreement. Your notice must include your name
              and address, your Let’s Eat username (if any), the email address
              you used to set up your Let’s Eat account (if you have one), and a
              CLEAR statement that you want to opt out of this Arbitration
              Agreement. The letter may opt out, at most, only one Merchant, and
              letters that purport to opt out multiple Merchants will not be
              effective as to any. No Merchant (or his or her agent or
              representative) may effectuate an opt out on behalf of other
              persons. If you opt out of this Arbitration Agreement, all other
              parts of this Agreement will continue to apply to you. Opting out
              of this Arbitration Agreement has no effect on any other
              arbitration agreements that you may have entered into with us or
              may enter into in the future with us. <br />
              <strong>6) Survival::</strong> This Arbitration Agreement will
              survive any termination of your relationship with Let’sEat. <br />
              <strong>7) Modification:</strong> Notwithstanding any provision in
              the Agreement to the contrary, we agree that if Let’s Eat makes
              any future material change to this Arbitration Agreement, it will
              not apply to any individual claim(s) that you had already provided
              notice of to the Let’s Eat.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              12. Third-Party Interactions
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              <strong>
                (a) Third-Party Websites, Applications and Advertisements:
              </strong>{" "}
              The Services may contain links to third-party websites
              (“Third-Party Websites") and applications (“Third-Party
              Applications") and advertisements (“Third-Party Advertisements")
              (collectively, “Third-Party Websites & Advertisements"). When you
              click on a link to a Third-Party Website, Third-Party Application
              or Third-Party Advertisement, Let’s Eat will not warn you that you
              have left Let’s Eat’s website or Services and will not warn you
              that you are subject to the terms and conditions (including
              privacy policies) of another website or destination. Such
              Third-Party Websites & Advertisements are not under the control of
              Let’s Eat. Let’s Eat is not responsible for any Third-Party
              Websites, Third-Party Applications or any Third-Party
              Advertisements. Let’s Eat does not review, approve, monitor,
              endorse, warrant, or make any representations with respect to such
              Third-Party Websites & Advertisements, or their products or
              services. You use all links in Third-Party Websites &
              Advertisements at your own risk. You should review applicable
              terms and policies, including privacy and data gathering practices
              of any Third-Party Websites or Third-Party Applications, and make
              whatever investigation you feel necessary or appropriate before
              proceeding with any transaction with any third party.
              <br /> <strong>(b) App Stores:</strong> You acknowledge and agree
              that the availability of the Application is dependent on the third
              party from which you received the Application license, e.g., the
              Apple iPhone or Android app stores (“App Store"). You acknowledge
              and agree that this Agreement is between you and Let’s Eat and not
              with the App Store. Let’s Eat, not the App Store, is solely
              responsible for the Software and the Services, including the
              Application and the Services, the content thereof, maintenance,
              support services and warranty therefor, and addressing any claims
              relating thereto (e.g., product liability, legal compliance or
              intellectual property infringement). In order to use the
              Application, you must have access to a wireless network, and you
              agree to pay all fees associated with such access. You also agree
              to pay all fees (if any) charged by the App Store in connection
              with the Application or the Services. You agree to comply with,
              and your license to use the Application is conditioned upon your
              compliance with, all applicable third-party terms of agreement
              (e.g., the App Store’s terms and policies) when using the
              Application. You acknowledge and agree that each App Store (and
              its affiliates) is an intended third-party beneficiary of this
              Agreement and has the right to enforce the terms and conditions of
              this Agreement.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              13. Social Media Guidelines.
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              Let’s Eat maintains certain social media pages for the benefit of
              the Let’s Eat community. By posting, commenting, or otherwise
              interacting with these pages, you agree to abide by the following
              social media guidelines:
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              We ask that you refrain from posting comments that:
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              Contain comments, links, content, images or videos that are
              obscene, sexuality explicit, profane, vulgar, or hateful in nature
              and/or promote racism, sexism, hatred or harm against any person,
              organization or company.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              Contain comments that threaten any person, organization or
              company.
              <br />
              Contain comments that defame, harass, or abuse any person.
              <br />
              Contain successive off-topic posts by a single user.
              <br />
              Contain or constitute spam.
              <br />
              Contain comments, links, images or video that encourage illegal
              activity.
              <br />
              Contain any materials that infringe upon the rights of any third
              party.
              <br />
              Contain confidential or proprietary information of any person,
              organization or company.
              <br />
              Contain false or misleading comments or claims about Let’s Eat or
              its competitors.
              <br />
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              Contain software viruses, Trojan horses, worms, time bombs, or any
              other computer code or files or disabling mechanisms that are
              designed to disrupt, damage, or limit the functioning of any
              software, hardware, telecommunications equipment, or that
              interfere with our operation.
            </Typography>

            <Typography
              paragraph
              sx={{
                fontSize: "14px",
                fontFamily: "Poppins",
                mt: 1,
                fontWeight: "500",
              }}
            >
              You are responsible for your comments or posts. The opinions,
              statements, and viewpoints expressed by community members do not
              necessarily reflect the opinions of Let’s Eat. As such, Let’s Eat
              does not endorse and is not responsible for any user comments. By
              posting, you are promising us that your post complies with these
              guidelines and doesn’t contain any of the restricted content
              listed above. Please note, however, that we may remove any post
              that we believe does not so comply.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              14. Transactions Involving Alcohol
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              You may have the option to request delivery of alcohol products in
              some locations and from certain Merchants. You agree that you will
              only order alcohol products if you are 18 years of age or older.
              You also agree that, upon delivery of alcohol products, you will
              provide us with valid government-issued identification proving
              your age and that the recipient will not be intoxicated when
              receiving delivery of such products. If you order alcohol
              products, you understand and acknowledge that Let’s Eat cannot
              accept your order of alcohol products, and the order will only be
              delivered if the Merchant accepts your order. Let’s reserves the
              right to refuse delivery if you are not 18years of older, if you
              cannot provide a valid government issued ID, if the name on your
              ID does not match the name on your order, or you are visibly
              intoxicated. If Let’s Eat is unable to complete the delivery of
              alcohol products for one or more of these reasons, you are subject
              to a non-refundable re-stocking fee.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              15. Indemnification
            </Typography>

            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              You agree to indemnify and hold harmless Let’s Eat and its
              officers, directors, employees, agents and affiliates (each, an
              “Indemnified Party"), from and against any losses, claims,
              actions, costs, damages, penalties, fines and expenses, including
              without limitation attorneys’ fees and expenses, that may be
              incurred by an Indemnified Party arising out of, relating to or
              resulting from (a) your User Content; (b) your misuse of the
              Software or Services; (c) your breach of this Agreement or any
              representation, warranty or covenant in this Agreement; or (d)
              your violation of any applicable laws, rules or regulations
              through or related to the use of the Software or Services. In the
              event of any claim, allegation, suit or proceeding alleging any
              matter potentially covered by the agreements in this section, you
              agree to pay for the defense of the Indemnified Party, including
              reasonable costs and attorneys’ fees incurred by the Indemnified
              Party. Let’s Eat reserves the right to assume the exclusive
              defense and control of any matter otherwise subject to
              indemnification by you, in which event you will fully cooperate
              with Let’s Eat in asserting any available defenses. You agree that
              the provisions in this section will survive any termination of
              your account, this Agreement, or your access to the Software
              and/or Services.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              16. Disclaimer of Warranties
            </Typography>

            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT OF
              LAW, YOUR USE OF THE SOFTWARE AND SERVICES IS ENTIRELY AT YOUR OWN
              RISK. CHANGES ARE PERIODICALLY MADE TO THE SOFTWARE AND SERVICES
              AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO YOU. THE SOFTWARE
              AND SERVICES ARE PROVIDED ON AN “AS IS" BASIS WITHOUT WARRANTIES
              OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
              TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. LET’S EAT MAKES NO WARRANTIES OR
              REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR
              TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE SOFTWARE OR
              SERVICES, OR THE SERVICES, SOFTWARE, TEXT, GRAPHICS OR LINKS.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              LET’S EAT DOES NOT WARRANT THAT THE SOFTWARE OR SERVICES WILL
              OPERATE ERROR-FREE OR THAT THE SOFTWARE OR SERVICES ARE FREE OF
              COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE
              SOFTWARE OR SERVICES RESULTS IN THE NEED FOR SERVICING OR
              REPLACING EQUIPMENT OR DATA, LET’S EAT SHALL NOT BE RESPONSIBLE
              FOR THOSE ECONOMIC COSTS.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              17. Internet Delays
            </Typography>

            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              The Software and Services may be subject to limitations, delays,
              and other problems inherent in the use of the Internet and
              electronic communications. Except as set forth in Let’s Eat’s
              privacy policy or as otherwise required by applicable law, Let’s
              Eat is not responsible for any delays, delivery failures, or
              damage, loss or injury resulting from such problems.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              18. Breach And Limitation of Liability
            </Typography>

            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              <strong>(a) General:</strong> You understand and agree that a key
              element of the Services and this Agreement is your and our mutual
              desire to keep the Services simple and efficient, and to provide
              the Software and Services at low cost. You understand and agree to
              the limitations on remedies and liabilities set forth in this
              Section 18 to keep the Software and Services simple and efficient,
              and costs low, for all users.
              <br />
              <strong>(b) Cap on Liability:</strong> TO THE FULLEST EXTENT
              PERMITTED BY LAW LET’S EAT’S AGGREGATE LIABILITY SHALL NOT EXCEED
              THE GREATER OF AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM YOU TO
              LET’S EAT IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE
              EVENT GIVING RISE TO SUCH CLAIM.
              <br />
              <strong>(c) Disclaimer of Certain Damages: </strong>TO THE FULLEST
              EXTENT OF LAW LET’S EAT SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE
              FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL,
              CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING
              PERSONAL INJURY, LOST PROFITS, PAIN AND SUFFERING, EMOTIONAL
              DISTRESS, AND LOSS OF DATA, REVENUE, USE AND ECONOMIC ADVANTAGE).
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              19. Exclusive Venue
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              To the extent the parties are permitted under this Agreement to
              initiate litigation in a court, both you and Let’s Eat agree that
              all claims and disputes arising out of or relating to the
              Agreement will be litigated exclusively in the Cayman Islands.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              20. Termination
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              If you violate this Agreement, Let’s Eat may respond based on a
              number of factors including, but not limited to, the egregiousness
              of your actions and whether a pattern of harmful behavior exists.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              In addition, at its sole discretion, Let’s Eat may modify or
              discontinue the Software or Service, or may modify, suspend or
              terminate your access to the Software or the Services, for any
              reason, with or without notice to you and without liability to you
              or any third party. In addition to suspending or terminating your
              access to the Software or the Service, Let’s Eat reserves the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal or injunctive redress. Even
              after your right to use the Software or the Services is
              terminated, this Agreement will remain enforceable against you.
              All provisions which by their nature should survive to give effect
              to those provisions shall survive the termination of this
              Agreement.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              21. Procedure for Making Claims of Copyright Infringement.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              It is Let’s Eat’s policy to terminate membership privileges of any
              User who repeatedly infringes copyright upon prompt notification
              to Let’s Eat by the copyright owner or the copyright owner’s legal
              agent. Without limiting the foregoing, if you believe that your
              work has been copied and posted on the Services in a way that
              constitutes copyright infringement, please provide our us with the
              following information at team@letseat.ky : (a) an electronic or
              physical signature of the person authorized to act on behalf of
              the owner of the copyright interest; (b) a description of the
              copyrighted work that you claim has been infringed; (c) a
              description of the location on the Services of the material that
              you claim is infringing; (d) your address, telephone number and
              e-mail address; (e) a written statement by you that you have a
              good faith belief that the disputed use is not authorized by the
              copyright owner, its agent or the law; and (f) a statement by you,
              made under penalty of perjury, that the above information in your
              notice is accurate and that you are the copyright owner or
              authorized to act on the copyright owner’s behalf.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              22. General
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              <strong>(a) No Joint Venture or Partnership:</strong>No joint
              venture, partnership, employment, or agency relationship exists
              between you, Let’s Eat or any third party provider as a result of
              this Agreement or use of the Software or Services.
              <br />
              <strong>(b) Choice of Law:</strong> This Agreement is governed by
              the laws of the Cayman Islands without giving effect to any
              principles that provide for the application of the law of any
              other jurisdiction.
              <br />
              <strong>(c) Severability:</strong> Except as otherwise provided in
              this Agreement, if any provision of this Agreement is found to be
              invalid, the invalidity of such provision shall not affect the
              validity of the remaining provisions of this Agreement, which
              shall remain in full force and effect.
              <br />
              <strong>(d) Notice:</strong> Where Let’s Eat requires that you
              provide an e-mail address, you are responsible for providing Let’s
              Eat with your most current e-mail address. In the event that the
              last e-mail address you provided to Let’s Eat is not valid, or for
              any reason is not capable of delivering to you any notices
              required or permitted by this Agreement, Let’s Eat’s dispatch of
              the e-mail containing such notice will nonetheless constitute
              effective notice. You may give notice to Let’s Eat Ltd. 11 Ashgo
              Street, George Town, Grand Cayman KY1-1101.
              <br />
              <strong>(e) Electronic Communications: </strong>For contractual
              purposes, you (1) consent to receive communications from Let’s Eat
              in an electronic form; and (2) agree that all terms and
              conditions, agreements, notices, disclosures, and other
              communications that Let’s Eat provides to you electronically
              satisfy any legal requirement that such communications would
              satisfy if they were in writing. You agree to keep your contact
              information, including email address, current.
              <br />
              <strong>(f) Transfer and Assignment:</strong> This Agreement, and
              any rights and licenses granted hereunder, may not be transferred
              or assigned by you, but may be assigned by Let’s Eat without
              restriction. Any attempted transfer or assignment in violation
              hereof shall be null and void. This Agreement binds and inures to
              the benefit of each party and the party’s successors and permitted
              assigns.
              <br />
              <strong>(g) Entire Agreement:</strong> This Agreement is the
              final, complete and exclusive agreement of the parties with
              respect to the subject matter hereof and supersedes and merges all
              prior discussions between the parties with respect to such subject
              matter..
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "18px", fontFamily: "Poppins", mt: 1 }}
            >
              23. Contact Information
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              Let’s Eat Ltd.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px", fontFamily: "Poppins", mt: 1 }}
            >
              11 Ashgo Street,
              <br />
              George Town,
              <br />
              Grand Cayman,
              <br />
              KY1-1101
            </Typography>
          </Box>
        </Container>

        <FooterV2 />
      </React.Fragment>
    </>
  );
}
