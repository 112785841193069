import {
  Box,
  Container,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from "@mui/material";
import React, { useEffect } from "react";
import Header2 from "../../../../layout/dashboard/header/header2";
import Colors from "../../../../assets/styles";
import FooterV2 from "../../../../layout/footerV2";
import { useLocation } from "react-router-dom";

export default function PrivacyPolicy() {
  const location =useLocation()
  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Header2 />

        <Container sx={{ py: 10, maxWidth: "1300px" }}>
          {/* Privacy Policy Header */}
          <Typography
            variant="h4"
            fontWeight="bold"
            mb={4}
            sx={{ fontFamily: "Poppins", textAlign: "center" }}
          >
            Privacy Policy
          </Typography>

          {/* Privacy Policy Content */}
          <Box sx={{ color: "black", lineHeight: 1.75, fontFamily: "Poppins" }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Let's Eat Privacy Policy
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              Let’s Eat may collect personal information to help you place and
              receive delivery orders and to improve the Let’s Eat service. This
              Privacy Statement explains how Let’s Eat Ltd. (“Let’s Eat,” “we,”
              “us,” or “our”) handles your personal information when you visit
              or use at  <Link href="/home" underline="hover" color="primary" sx={{fontWeight:"bold"}}> http://www.letseat.com   </Link>{" "}
              (the "Site"), the Let's Eat mobile application, or any other
              website or mobile application that links to this Privacy Statement
              (collectively the "Services").
            </Typography>
            <Typography paragraph sx={{ fontSize: "14px",fontFamily:"Poppins" }}>
              Your access to and use of our Services are subject to our [INSERT
              LINK TO CONSUMER TERMS OF SERVICE], which define some of the terms
              used throughout this Privacy Statement.
            </Typography>

            {/* Information Collection */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Collection of Information
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              When you use our Services we may collect or receive information
              about you. We generally do not permit people to deal with us
              anonymously or using a pseudonym. If you do not provide us with
              your personal information, we may not be able to provide the
              Services to you.
            </Typography>

            {/* Information Provided Directly */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Information You Provide Directly
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              You may provide information to us when you use our Services,
              including when you register with Let’s Eat, search within or place
              orders through our Services, contact us with inquiries, respond to
              surveys, or otherwise access our Services. This information may
              include your name, email address, phone number, delivery address,
              and payment information, including credit card information and
              billing address used to complete the purchase transaction, and
              other information that may identify you. When you place an order
              through our Services we collect information related to that order,
              including searches conducted through the Services, the products or
              services purchased, and the date and time of the purchase and
              delivery.
            </Typography>

            {/* Information Collected Automatically */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Information We Collect Automatically
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              We also may receive and store certain information about you and
              your device(s) automatically when you access or use our Site and
              Services. This information may include:
            </Typography>
            <List
              sx={{
                fontSize: "14px",fontFamily:"Poppins",
              
                listStyleType: "none",
                "& .MuiListItem-root": { display: "list-item" },
              }}
            >
              <ListItem sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="Information related to the device you use to access our Services;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="The type of web browser and operating system you use to access our Services;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="Your IP address;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="The name of your ISP or mobile service provider;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="The specific pages that you visit, content you view, features you use, and the date and time of your visits and your search queries;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="The websites you visited before and after visiting our Services;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",}}>
                <ListItemText
                  sx={{ fontSize: "13px !important" }}
                  primary="Information about your interactions with our emails and other communications."
                />
              </ListItem>
            </List>

            {/* Information from Publicly Available Sources */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins"}}
            >
              Information from Publicly Available Sources and Third Parties
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "bold" }}
            >
              We may also collect information about you from publicly available
              sources or third parties. For example, we may receive certain
              information about you from Facebook or Google to log into our
              Services. If you provide a third-party service with your Let's Eat
              delivery order(s), we may receive information about your
              purchases, including your phone number, the product you purchased,
              and the delivery location.
            </Typography>

            {/* Use of Information */}
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Use of Information
            </Typography>
            <Typography sx={{ fontSize: "14px",fontFamily:"Poppins" }} paragraph>
              We use the information we collect to provide the services you
              request, to:
            </Typography>
            <List
              sx={{
                listStyleType: "none",
                "& .MuiListItem-root": { display: "list-item" },
              }}
            >
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="We may use the information we collect or receive about you to:"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Connect your orders to a Merchant;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Complete your purchase transactions;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Contact you in connection with your orders and deliveries;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Contact you with promotional offerings or other communications that may be of interest to you;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Respond to your questions and assist with problems with our Services;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Personalize the content and features you see when you visit our Services;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Serve advertisements tailored to your interests;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Improve the content and functionality of our Services;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Help the Merchants better understand their business and improve their offerings;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Better understand our users’ demographics, interests and behaviors;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Enhance other information we have about you to help us better understand you and determine your interests;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Provide recommendations to you;"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Manage our everyday business needs, such as auditing, administration of our Services, forum management, fulfillment, analytics, fraud prevention, and enforcement of our corporate reporting obligations and Terms of Service, or to comply with the law; and"
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="Calculate the total number of visitors to our Services and to each page or feature of our Services."
                />
              </ListItem>
              <ListItem  sx={{  padding:"0 !important",    }}>
                <ListItemText
                  sx={{ fontSize: "14px",fontFamily:"Poppins" }}
                  primary="We may use your information for any other purpose disclosed to you at the time we collect or receive the information, or otherwise with your consent or as permitted or required by law."
                />
              </ListItem>
            </List>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "bold" }}
            >
              If you send text messages using the telephone number available on
              the Services, we may use a third-party service provider to track
              these text messages. We track these text messages for fraud
              prevention, to ensure appropriate charging of fees, to enforce our
              Terms and Conditions, and for quality and training purposes. As
              part of this process, Let’s Eat and its service provider will
              receive in real time and store data about your text message,
              including the date and time of the text message, your phone
              number, and the content of the text message.
            </Typography>
            <Typography paragraph sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}>
              Any communication or material you transmit to us via e-mail or
              otherwise, including any data, questions, comments, suggestions or
              the like, but not including your personal information and any
              requests, comments or concerns regarding your personal
              information, will be treated as non-confidential and
              non-proprietary.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Location Information
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              When you use the Services, we may collect precise location data
              about the progress of your order from the Let’s Eat app. If you
              permit the Services to access location services through the
              permission system used by your mobile operating system
              (“Platform”) or browser, we may also collect the precise location
              of your device when the Let’s Eat app is running in the foreground
              or background of your device while we are processing your order.
              We may also derive your approximate location from your IP address.
              We use your location information to help you select your delivery
              address, to provide more accurate deliveries of your orders, to
              provide recommendations to you, and to enhance your user
              experience and improve the services. You can choose whether to
              enable the location tracking feature through the settings on your
              device or Platform or when prompted by the Let’s Eat mobile app.
              If you choose to disable the location feature, Let’s Eat will not
              receive precise location information from your device, which may
              compromise the accuracy of deliveries in some situations, for
              instance, if you are located in a large area, such as a park.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Sharing with Third Parties
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              To help us run our business, we may share your information with
              entities that provide services to us, including third parties that
              provide payment processing, advertising services, web analytics,
              data processing, IT services, customer support and other services.
              We may share your name, phone number, order information, customer
              feedback and other information related to your orders with
              Merchants. We may also disclose aggregated or anonymized
              information to third parties.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              When Required By Law
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              We recognize that information related to your orders could contain
              private information. However, we may be required to disclose your
              information to third parties including law enforcement agencies
              when required to protect and defend our legal rights, protect the
              safety and security of users of our Services, prevent fraud,
              comply with the law, respond to legal process, or a request for
              cooperation by a government entit
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Corporate Transactions
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              In the event of sale, transfer, merger, reorganization,
              dissolution, or similar event we may transfer your information to
              one or more third parties as part of that transaction.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              With your Consent
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              We also may share or disclose your information with your consent
              or at your direction.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Data Retention
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              We will retain your personal information for as long as your
              account is active or as needed to provide you services and to
              maintain a record of your transactions for financial reporting
              purposes. We will also retain and use your personal information as
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              Let’s Eat may aggregate and anonymize data and may retain
              aggregate or anonymized data indefinitely.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Links to Third Party Sites
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              The Services may contain links to other websites not operated or
              controlled by Let’s Eat. Such websites are not under our control
              and we are not responsible for their privacy policies or
              practices. If you provide any personal information through any
              such third-party website, we recommend that you familiarize
              yourself with the privacy policies and practices of that third
              party.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Social Networking
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              You may choose to enable or log in to our Services via various
              online services, including social networking services like
              Facebook and Google. Our Services also may enable you to access
              social networking services such as Facebook, Twitter, or Instagram
              (collectively, “Social Network”) directly or indirectly through
              our Services.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              When you link a Social Network account to Let’s Eat or log into
              our Services using your Social Network account, we may collect
              relevant information necessary to enable our Services to access
              that Social Network and your data contained within that Social
              Network. We also may share your information with the operator of
              that Social Network to facilitate or enhance delivery of that
              Social Network or other services to you. A Social Network may
              provide us with access to certain information that you have
              provided to them, and we will use, store, and disclose such
              information in accordance with this Privacy Statement.
              Additionally, a Social Network may collect personal information
              and information about your use of our Site or Services
              automatically. The manner in which a Social Network collects,
              uses, stores, and discloses your information is governed by the
              policies of such third parties and Let’s Eat shall have no
              liability or responsibility for the privacy practices or other
              actions of any Social Network that may be enabled within our
              Services.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              You may also have the option of posting your activities on our
              Services and other content to a Social Network when you access
              content through our Services. For example, you may post to
              Facebook that you placed an order with Let’s Eat. Keep in mind
              that your activity on a Social Network is governed by that Social
              Network and not by Let’s Eat, and may be visible or accessible to
              the public.
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Your Choices
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              If you are a registered Let’s Eat user you may access or modify
              certain of your personal information by logging into our Site
              using your username and password and visiting the “Account” page.
              Registered Let’s Eat users may also access the “Account” page to
              manage their subscriptions. You may contact us at info@letseat.ky
              to access, modify, or deactivate your account. You may disable our
              ability to use cookies by using the cookie controls in your web
              browser (see “Use of Cookies” section below).
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              If we send you marketing emails about services that may interest
              you, each email will contain instructions permitting you to
              “opt-out” of receiving future marketing or other communications.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Children’s Information
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              Our Services are not intended for children under 13 years of age
              and we do not knowingly collect personal information from children
              under the age of 13. If you are a parent or guardian of a child
              under the age of 13 and believe he or she has disclosed personally
              identifiable information to us please contact us at
              info@letseat.ky. A parent or guardian of a child under age 13 may
              review and request deletion of the child’s personal information.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Referral Program
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              Let’s Eat allows users to invite friends to sign up for our
              Services by submitting a friend’s name and email address to Let’s
              Eat. Sometimes we offer a promotional value for every friend that
              signs up and places an order through your referral link. By
              choosing to invite a friend you represent that you have received
              the friend’s consent to provide his or her name and email address
              to us. Let’s Eat will use that information to send an email
              inviting him or her to visit the site and will store that
              information to track the success of the referral program.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              International Users
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              The information collected as part of that use may be transferred
              to and maintained on servers located in countries other than the
              Cayman Islands, such as in the United States. We may from time to
              time, use third party storage providers for this purpose. Since
              Let’s Eat is based in the Cayman Islands, any information we
              obtain about you will at a minimum be stored in accordance with
              Cayman Islands privacy laws, regulations, and standards, which may
              not be equivalent to the laws in your country of residence. By
              using our Services, you consent to this collection, transfer,
              storage, and processing of information to, and in, countries other
              than the Cayman Islands. While it is not practicable to specify
              all of these countries, regions may include (but are not limited
              to) North America, South America and Europe.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Security
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              Let’s Eat has implemented administrative, technical, and physical
              security controls that are designed to safeguard personal
              information. However, no online activity is ever fully secure or
              error-free. Therefore, Let’s Eat does not guarantee that your
              personal information is absolutely secure. Please keep this in
              mind when disclosing any personal information or other information
              to Let’s Eat.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              You are responsible for safeguarding your username and password.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins" }}
            >
              Use of Cookies
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              We use cookies, web beacons, pixels, and similar technologies
              (“Cookies”) to collect information and personalize your experience
              with our Services. Cookies are small web files that a site or its
              provider transfers to your device’s hard drive through your web
              browser that enables the site’s or provider’s system to recognize
              your browser and remember certain information. We use Cookies to
              improve your convenience, for example, to recognize you when you
              return to Let’s Eat and to remember information you have already
              provided, such as items already in your order cart. We also use
              Cookies to collect information about your activities over time and
              across third party websites or other online services in order to
              deliver content and advertising tailored to your interests. You
              may disable the use of Cookies by modifying your browser settings.
              If you choose to disable cookies you may not be able to fully
              experience the interactive features of our Services.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              Online Analytics and Advertising
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              We and our third-party vendors may use automated technologies
              (including cookie identifiers on our website), along with other
              collected information, to tailor ads or deliver content when you
              are on our Services or on other devices, apps or websites.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              We (or our service providers) may use the information we
              collect—for instance, IP addresses and unique mobile device
              identifiers—to locate or try to locate the same unique users
              across multiple browsers or devices (such as smartphones, tablets,
              or computers), or work with providers that do this, in order to
              better tailor content and features and provide you with a seamless
              experience across devices. If you wish to opt out of cross device
              tracking for purposes of interest-based advertising, you may do so
              through your device settings. We may also use cross-device
              targeting to help identify our users and serve advertising. This
              type of advertising is often called “interest-based” or
              “personalized” advertising—and when it occurs on mobile apps,
              “cross-app” advertising.
            </Typography>

            <Typography
              paragraph
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1, fontWeight: "500" }}
            >
              <strong>
                {" "}
                You can learn more about interest-based advertising on the web,
                or opt out of receiving those ads, by visiting (i) the Network
                Advertising Initiative’s Consumer
              </strong>{" "}
              <span style={{ color: Colors.primary, fontWeight: "bold" }}>
                {" "}
                Opt-Out link
              </span>{" "}
              or (ii) the Digital Advertising Alliance’s Consumer{" "}
              <span style={{ color: Colors.primary, fontWeight: "bold" }}>
                {" "}
                Opt-Out link
              </span>
              to opt-out of receiving tailored advertising from companies that
              participate in those programs. To opt out of Google Analytics for
              display advertising or customize Google display network ads, you
              can visit the
              <span style={{ color: Colors.primary, fontWeight: "bold" }}>
                Google Ads Setting Page
              </span>
              .
            </Typography>

            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              As to cross-app advertising, each operating system (iOS for Apple
              phones, Android for Android devices, and Windows for Microsoft
              devices) provides its own instructions on how to prevent the
              delivery of tailored in-application advertisements. You should
              view your device or system “Settings” to determine how you can opt
              out of use of your device ID for “cross-app” personalized
              advertising.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              Please note that to the extent advertising technology is
              integrated into our Services, you may still receive advertisements
              even if you opt-out. In that case, the advertising will not be
              tailored to your interests. Also, we do not control any of the
              above opt-out links or whether any particular company chooses to
              participate in these opt-out programs.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              Changes to This Privacy Statement
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              Our Services may change from time to time. As a result, we reserve
              the right to update or modify this Privacy Statement at any time
              and from time to time without prior notice. If we make any
              material changes to the way we use or disclose your personal
              information, we will notify you by email, at the email address you
              have provided to us. If you object to any changes you must cease
              using our Services. Please review this Privacy Statement
              periodically by visiting this webpage. This Privacy Statement was
              last updated on the date indicated at the top of this page. Your
              continued use of our Services after any changes or revisions to
              this Privacy Statement indicates your agreement to the terms of
              the revised Privacy Statement.
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              Contact Us
            </Typography>

            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              If you have any questions or concerns relating to this Privacy
              Statement or our privacy practices please contact us at:
            </Typography>
            <Typography
              variant="h6"
              fontWeight="500"
              sx={{ fontSize: "14px",fontFamily:"Poppins", mt: 1 }}
            >
              info@letseat.ky
            </Typography>
          </Box>
        </Container>

        <FooterV2 />
      </React.Fragment>
    </>
  );
}
