  import { Fragment, useEffect } from "react";
  import Colors from "./app/assets/styles";
  import { createTheme, ThemeProvider } from "@mui/material";
  import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
  import DashboardLayout from "./app/layout/dashboard";
  import AdminRoutes from "./app/routes/admin";
  import "@fontsource/noto-sans";
  import Login from "./app/layout/login/index";
  import useAuth from "./app/hooks/useAuth";
  import { ToasterContainer } from "./app/components/Toaster";
  import GoogleAuth from "./app/views/admin/Login/googleAuth";
  import Checkout from "./app/views/admin/checkout/checkout"
  import "./App.css"
  import Home from "./app/views/admin/dashboard/home";
  import PrivacyPolicy from "./app/views/admin/pages/PrivacyPolicy";
  import PartnerWithUs from "./app/views/admin/pages/PartnerWithUS";
  import Retail from "./app/views/admin/pages/Retail";
  import TermsAndCondition from "./app/views/admin/pages/TermsAndConditions";
import SuccessPage from "./app/views/admin/pages/SuccessPage";


  const theme = createTheme({
    
    typography: {
      fontFamily: "Noto Sans, sans-serif",
      h1: {
        fontSize: "72px",
      },
      h2: {
        fontSize: "60px",
      },
      h3: {
        fontSize: "48px",
      },
      h4: {
        fontSize: "36px",
      },
      h5: {
        fontSize: "24px",
      },
      h6: {
        fontSize: "18px",
      },
      subtitle1: {
        fontSize: "16px",
      },
      subtitle2: {
        fontSize: "14px",
        fontWeight: 400,
      },
      caption: {
        fontSize: "12px",
      },
    },
    palette: {
      primary: {
        main: Colors.primary
      }
    }
  })

  function App() {
    useEffect(() => {
      if (window.location.pathname == "/stores") {
        window.location.href = "https://letseat.ky/stores";
      }
      else if (window.location.pathname == "/shops") {
        window.location.href = "https://letseat.ky/shops";
      }
      else if (window.location.pathname == "/staffs") {
        window.location.href = "https://letseat.ky/staffs";
      }
    }, [window.location.pathname]);


   
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
        <ToasterContainer/>
          <BrowserRouter>
            <Routes>
            <Route
                path="/"
                element={<Home />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/googleAuth" element={<GoogleAuth />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/enquirydelivery" element={<PartnerWithUs />} />
              <Route path="/retailenquirydelivery" element={<Retail />} />
              <Route path="/consumerterms" element={<TermsAndCondition />} />
              <Route path="/redirect" element={<SuccessPage />} />

            
              <Route element={ <DashboardLayout /> }>
                {AdminRoutes.map((item, i) => (
                  <Route key={i} path={item.path} element={item.component} />
                ))}
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Fragment >
    );
  }

  export default App;
