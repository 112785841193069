import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Container,
  Box,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  TextField,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


import { styled } from "@mui/system";
import AddPaymentMethodModal from "../Payment/AddPaymentMethodModal";
import WalletApiCall from "../../../../api/WalletServices";
import { useSelector } from "react-redux";
import Colors from "../../../../assets/styles";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";

const StyledCard = styled(Card)({
  background:
    "linear-gradient(to right, rgba(255,255,255,0.9), rgba(240,240,240,0.9))",
  transition: "background 0.3s ease",
  height: "200px", // Set your desired height here
  borderRadius: "12px", // Adjust border radius to match the image
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for elevation effect
  border: "1px solid rgba(0, 0, 0, 0.12)",
  position:"relative"
});

const Wallet = () => {
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [openVoucher, setOpenVoucher] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletHistory, setWalletHistory] = useState([]);

  const userData = useSelector(state => state.userData.userData);
  const isWallet = useSelector((state) => state.cartData.isWallet);
  const [isWalletEnabled, setIsWalletEnabled] = useState(isWallet);
  const navigate = useNavigate()
  const location = useLocation()
  const paymentCardData = useSelector(
    state => state.cartData.paymentCardData,
  );
  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [location]);

  const getWalletBalance = async () => {
    try {
      let param = {
        user_id: userData.id,
      };
      const result = await WalletApiCall.getWalletBalance(param);
      setWalletBalance(result);
    } catch (error) {
      console.log('error', error);
    }
  };
  const getWalletHistory = async () => {
   
    try {
      let param = {
        user_id: userData.id,
      };
      const result = await WalletApiCall.getWalletHistory(param);
      setWalletHistory(result);
    } catch (error) {
      console.log('error', error);
      
    } finally {
     
    }
  };
  const handleClickOpenPaymentMethod = () => {
    setOpenPaymentMethod(true);
  };


  const handleClosePaymentMethod = () => {
    setOpenPaymentMethod(false);
  };

 

  useEffect(() => {
  
    getWalletBalance();
  
  }, []);
  useEffect(() => {
    getWalletHistory()

  }, []);
  return (
    <Container  sx={{mt:12,maxWidth:"1300px"}}>
      {/* Header */}
      <AppBar position="static"  color="transparent" elevation={0}>
        <Toolbar sx={{padding:"0 !important"}}>
        <Box sx={{ fontWeight: "bold" ,display:"flex",gap:"10px",alignItems:"center"}}>
      <Box sx={{background:Colors.grey,borderRadius:"50%",width:"40px"}}>
              <IconButton onClick={()=>navigate(-1)}> 
                <ArrowBackIosNew />
              </IconButton>
            </Box>
        <Typography variant="h5" sx={{fontWeight:"bold"}}>Let's Eat Wallet</Typography>
      </Box>
         
        </Toolbar>
      </AppBar>

      {/* Wallet Card */}
      <Box mt={4}>
        <StyledCard>
          <CardContent>
        <Box mt={4}  onClick={handleClickOpenPaymentMethod} sx={{cursor:"pointer",position:"absolute",right:'20px',background:Colors.black,color:Colors.white,p:1,borderRadius:"15px"}}>
        <Typography variant="h6" sx={{ fontWeight: "bold",fontSize:"15px" }}>
        Add Money
        </Typography>
      </Box>
            <Typography variant="subtitle1">Let's Eat Cash</Typography>
            <Box mt={2}>
              <Typography variant="h4">$ {walletBalance}</Typography>
            </Box>
          </CardContent>
        </StyledCard>
      </Box>

      {/* Payment Methods */}
     
      <Dialog
        open={openPaymentMethod}
        onClose={handleClosePaymentMethod}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            minHeight: "600px",
            borderRadius: "12px", // Adjusted to match rounded corners
            border: "1px solid rgba(0, 0, 0, 0.12)", // Subtle border
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for elevation
          },
        }}
      >
        <DialogContent>
        <AddPaymentMethodModal
         getWalletHistory={getWalletHistory}
         getWalletBalance={getWalletBalance()}
            modalVisible={openPaymentMethod}
            setIsWalletEnabled={setIsWalletEnabled}
            setClose={() => setOpenPaymentMethod(false)}
            fromWallet={true}
     
            onClick={() => {
              setOpenPaymentMethod(false);
            }}
   

            setOpenPaymentMethod={() =>
              setOpenPaymentMethod(!openPaymentMethod)
            }
            // removeModal={removeCardModal}
            // setRemoveModal={setRemoveCardModal}
            closeAllModal={() => {
              // setPaymentOptionModal(false);
              // setPaymentMethodOptionModal(false);
              setOpenPaymentMethod(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <Box mt={2}>
        <Divider />
      </Box>

      <Box mt={4}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Statements
        </Typography>
        <Box mt={2}>
         
          <TableContainer component={Paper} sx={{ maxHeight: 600, overflow: 'auto',mb:4 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{background:Colors.primary,color:Colors.white,position:"sticky",top:0}}>
          <TableRow>
            
            <TableCell sx={{color:Colors.white}}>Date</TableCell>
            <TableCell sx={{color:Colors.white}}>Notes</TableCell>
            <TableCell sx={{color:Colors.white}}>Type</TableCell>
            <TableCell sx={{color:Colors.white}} align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {walletHistory?.map((item)=>(
            <>
          <TableRow>

            <TableCell>{moment(item.tran_date).format("DD/MM/YYYY")}</TableCell>
            <TableCell>{item.narration}</TableCell>
            <TableCell sx={{color:item.Type == "CR" ? Colors.green : Colors.red}}>{item.Type}</TableCell>
            <TableCell align="right">{item.amt}</TableCell>
          </TableRow>
            
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Box>
      </Box>
      {/* <Dialog
        open={openVoucher}
        onClose={handleCloseVoucher}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            minHeight: "600px",
            borderRadius: "12px", // Adjusted to match rounded corners
            border: "1px solid rgba(0, 0, 0, 0.12)", // Subtle border
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for elevation
          },
        }}
      >
        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Add voucher
            </Typography>
            <IconButton onClick={handleCloseVoucher}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            placeholder="Enter your voucher code"
            variant="outlined"
            value={voucherCode}
            onChange={handleVoucherCodeChange}
            margin="normal"
            InputProps={{
              style: { borderRadius: "8px", backgroundColor: "#f5f5f5" },
            }}
          />
          <Typography variant="body2" color="textSecondary">
            By adding, you acknowledge and agree that Let's Eat can share
            information on how this voucher is used with the company issuing
            this voucher to you. Limited availability. No cash value. Let's Eat
            Voucher does not apply to tips. Offer is non-transferable, and
            subject to change or cancellation. Issues involving redemption
            and/or use of the Uber Voucher should be directed to the company
            issuing this voucher to you.
          </Typography>

          <Box mt={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{
                borderRadius: "8px",
                backgroundColor: voucherCode ? "#000" : "#e0e0e0",
                color: voucherCode ? "#fff" : "#9e9e9e",
              }}
              disabled={!voucherCode}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
      </Dialog> */}
    </Container>
  );
};

export default Wallet;
